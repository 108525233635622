import React, {useState, useEffect} from 'react'
import {Navigate, useParams} from 'react-router-dom'
import ReactQuill from 'react-quill'



const EditBlog = () => {
	// const {id} = useParams()
	// const [title, setTitle] = useState('')
	// const [summary, setSummary] = useState('')
	// const [content, setContent] = useState('')
	// const [files, setFiles] = useState('')
	// // const [cover, setCover] = useState('')
	// const [redirect, setRedirect] = useState(false)

	// useEffect(() => {
	// 	fetch('https://glintz-serverss.vercel.app/post/'+id).then(response => {
	// 		response.json().then(postInfo => {
	// 			setTitle(postInfo.title)
	// 			setContent(postInfo.content)
	// 			setSummary(postInfo.summary)
	// 		})
	// 	})
	// }, [])


	// async function updatePost(ev) {
	// 	ev.preventDefault()
	// 	const data = new FormData()
	// 	data.set('title', title)
	// 	data.set('summary', summary)
	// 	data.set('content', content)
	// 	if(files?.[0]) {
	// 		data.set('file', file?.[0])
	// 	}
	// 	await fetch('http://localhost:4000/post/', {
	// 		method: 'PUT',
	// 		body: data,
	// 		credentials: 'include',
	// 	})
	// 	if(response.ok) {
	// 		setRedirect(true)
	// 	}
	// }

	// if(redirect) {
	// 	return <Navigate to={'/admin/blog/'+id} />
	// }



	// const modules = {
	// toolbar: [
	// 	[{'header': [1,2,false]}],
	// 	['bold', 'italic', 'underline', 'strike', 'blockquote'],
	// 	[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}],
	// 	['link', 'image'],
	// 	['clean']
	// ]}

	return (
		<>
			{/*<form onSubmit={updatePost} className="px-20 py-20">
				<input 
					className="border border-black py-2 px-5"
					type="title"
					placeholder="Title"
					value={title}
					onChange={ev => setTitle(ev.target.value)}
				/><br/><br/>
				<input 
					className="border border-black py-2 px-5"
					type="summary"
					placeholder="Summary"
					value={summary}
					onChange={ev => setSummary(ev.target.value)}
				/><br/><br/>
				<input type="file"
					onChange={ev => setTitle(ev.target.files)}
				/><br/><br/>
				<ReactQuill
					value={content}
					onChange={newValue => setContent(newValue)}
					module={modules}
				/>
				<button>Create post</button>
			</form>*/}
		</>
	)
}

export default EditBlog