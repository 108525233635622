import React from 'react'
import {Helmet} from "react-helmet";
import cinematography from '../assets/about/cinematography.jpg'
import photography from '../assets/about/photography.jpg'
import talent from '../assets/about/talent-development.jpg'
import documentary from '../assets/about/documentary.jpg'
import total from '../assets/about/total-logo.png'
import gordons from '../assets/about/gordons.png'
import fresh from '../assets/about/so-fresh.png'
import ekiti from '../assets/about/ekiti-logo.png'
import oldLagos from '../assets/about/old-lagos.png'
import temitope from '../assets/home/tpj4s.jpg'
import leonard from '../assets/about/leonard.jpg'
import fola from '../assets/about/fola.jpg'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import homeDocumentary from '../assets/home/documentary.jpg'
import homeWedding from '../assets/home/wedding4.jpg'
import homeEvent from '../assets/home/event1.jpg'
import video from '../assets/home/video.jpg'
import hero from '../assets/about/head-section.jpg'



const About = () => {

	// hover section
	const triggerElements = document.querySelectorAll('.hover-trigger');
	const imagePlaceholders = document.querySelectorAll('.image-placeholder');

	triggerElements.forEach((triggerElement, index) => {
	  triggerElement.addEventListener('mouseenter', () => {
	    imagePlaceholders.forEach((imagePlaceholder, imageIndex) => {
	      if (imageIndex === index) {
	        imagePlaceholder.classList.remove('hidden');
	      } else {
	        imagePlaceholder.classList.add('hidden');
	      }
	    });
	  });
	});

	// Keep the currently displayed image when not hovering over any trigger
	imagePlaceholders.forEach((imagePlaceholder, imageIndex) => {
	  imagePlaceholder.addEventListener('mouseenter', () => {
	    triggerElements[imageIndex].classList.add('hover:underline');
	  });

	  imagePlaceholder.addEventListener('mouseleave', () => {
	    triggerElements[imageIndex].classList.remove('hover:underline');
	  });
	});


	return (
		<>
			<Helmet>
	    	    <title>Glintz Photography - About | Wedding, Portrait, Documentary, Events, Storytelling</title>
		        <meta name="description" content="Glintz Photography about page" />
		    </Helmet>
			<Navbar />
			<div className="">
			{/*Header*/}
				<div className="h-[700px] pb-20 pt-40 hidden xl:block lg:block md:block ">
					<div className="h-full xl:mx-20 lg:mx-20 md:mx-10 mt-50 bg-center bg-no-repeat justify-center items-center flex" 
						style={{backgroundImage:`url(${hero})`, 
							backgroundSize: "100%",
						    backgroundPosition: "center center"}}>
						<h1 className="text-[3.5em] uppercase text-white text-center">about us</h1>
					</div>
				</div>

				{/*first section*/}
				<div className="grid grid-cols-1 w-full text-center py-20 xl:px-20 lg:px-20 px-10">
					<p className="mb-5 text-custom-gray-text text-xl italic">We picture the future</p>
					<p className="text-custom-gray-text myCustomFontText" style={{fontFamily: "Muli"}}>Glintz Photography is a multimedia Brand, set up to document, tell African stories of love, places, people and their 
					culture through creative visuals(still and motion pictures). Our storytelling expertise ranges from Portraits, Documentary 
					to Events (weddings, Corporate and concerts). Our style is clean, simple, genuine and composed to communicate essence. 
					We know every event is unique and it is always our delight to create exquisite, candid and creative content with real deep 
					sense of mood in raw emotions peculiar to each client. We look forward to starting a long and lasting 
					meaningful relationship with you. We are based in Lagos, Nigeria and we are available to travel within and outside Africa.</p>
				</div>

				<div className="xl:px-40 px-10 lg:px-20 py-20">
					<h2 className="uppercase text-center pb-20 xl:text-4xl lg:text-4xl md:text-4xl text-3xl text-custom-gray-head-text">who we are</h2>
				
					<div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-10">
						<div>
							<img src={photography} alt="photographer" className="mb-5" />
							<h3 className="uppercase mb-5 xl:text-2xl lg:text-2xl md:text-2xl text-xl text-custom-gray-head-text tracking-[.1em]">photographer</h3>
							<p className="text-custom-gray-text" style={{fontFamily: "Muli"}}>We create and document timeless images in elegant and simple form, evoking true emotions.</p>
						</div>
						<div>
							<img src={cinematography} alt="cinematographer" className="mb-5" />
							<h3 className="uppercase mb-5 xl:text-2xl lg:text-2xl md:text-2xl text-xl text-custom-gray-head-text tracking-[.1em]">cinematographer</h3>
							<p className="text-custom-gray-text" style={{fontFamily: "Muli"}}>We help you tell stories in a way that is personal and original, giving you a reason to gigle years after your event.</p>
						</div>
						<div>
							<img src={talent} alt="talent developer" className="mb-5" />
							<h3 className="uppercase mb-5 xl:text-2xl lg:text-2xl md:text-2xl text-xl text-custom-gray-head-text tracking-[.1em]">talent developer</h3>
							<p className="text-custom-gray-text" style={{fontFamily: "Muli"}}>We inspire and empower creative talents in photography through our academy</p>
						</div>
					</div>
				</div>

				{/*Team*/}
				<div className="xl:px-40 xl:py-20 py-10 px-10">
					<div className="xl:py-14 py-10 text-center">
						<h1 className="xl:text-4xl lg:text-4xl md:text-3xl text-3xl uppercase text-custom-gray-head-text">our photography team</h1>
						<p className="text-custom-gray-text italic text-xl">Meet our team</p>
					</div>
					<div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
						<div className="text-custom-gray-text">
							<img src={temitope} alt="Temitope" className="xl:mb-10 mb-5" />
							<p className="capitalize italic text-lg">creative director</p>
							<p className="uppercase tracking-[.15em]">temitope jalekun</p>
							<p className="uppercase">in<span>-</span><span>tw</span></p>
						</div>
						<div className="text-custom-gray-text">
							<img src={leonard} alt="leonard" className="xl:mb-10 mb-5" />
							<p className="capitalize italic text-lg">photographer</p>
							<p className="uppercase tracking-[.15em]">leonard ikediuba</p>
						</div>
						<div className="text-custom-gray-text">
							<img src={fola} alt="fola" className="xl:mb-10 mb-5" />
							<p className="capitalize italic text-lg">retoucher</p>
							<p className="uppercase tracking-[.15em]">fola</p>
						</div>
					</div>
				</div>

				<div className="xl:flex lg:flex md:flex grid grid-cols-1 xl:pl-10 ">
					<div className="my-auto order-2 xl:order-1 lg:order-1 md:order-1 px-10 xl:px-20 lg:px-0 md:px-0">
						<ul className="uppercase text-custom-gray-text tracking-[.15em] xl:text-4xl lg:text-4xl md:text-3xl sm:text-2xl text-2xl">
							<li className="py-4 hover:underline"><a href="/portfolio/portrait" className="hover-trigger" data-target="portrait">portrait</a></li>
							<li className="py-4 hover:underline"><a href="/portfolio/wedding" className="hover-trigger" data-target="wedding">wedding</a></li>
							<li className="py-4 hover:underline"><a href="/portfolio/events" className="hover-trigger" data-target="events">events</a></li>
							<li className="py-4 hover:underline"><a href="/portfolio/documentary" className="hover-trigger" data-target="documentary">documentary</a></li>
							<li className="py-4 hover:underline"><a href="/portfolio/commercial" className="hover-trigger" data-target="commercial">commercial</a></li>
						</ul>
					</div>
					<div className="order-1 xl:order-2 lg:order-2 md:order-2 px-10 xl:px-0 lg:px-0 md:px-0">
						<img src={video} 
							alt="portrait"
					      className="image-placeholder"
					      data-target="portrait"
					     />
						<img
					      src={homeWedding}
					      alt="wedding"
					      className="hidden image-placeholder"
					      data-target="wedding"
					    />
					    <img
					      src={homeEvent}
					      alt="events"
					      className="hidden image-placeholder"
					      data-target="events"
					    />
					    <img
					      src={homeDocumentary}
					      alt="documentary"
					      className="hidden image-placeholder"
					      data-target="documentary"
					    />
					    <img
					      src={homeEvent}
					      alt="commercial"
					      className="hidden image-placeholder"
					      data-target="commercial"
					    />
					</div>
				</div>


			{/*brands*/}
			<div className="xl:-mt-20 grid 
				xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 
				bg-custom-light-bg xl:py-40 lg:py-40 md:py-20 py-20 
				xl:px-64 lg:px-30 md:px-30 justify-center ">
				<div className="flex justify-center items-center mb-5 xl:mb-0 lg:mb-0 md:mb-0 sm:mb-0">
					<img src={total} alt="" className="xl:w-3/6 lg:w-3/6 w-2/6" />
				</div>
				<div className="flex justify-center items-center mb-5 xl:mb-0 lg:mb-0 md:mb-0 sm:mb-0">
					<img src={gordons} alt="" className="xl:w-3/6 lg:w-3/6 w-2/6" />
				</div>
				<div className="flex justify-center items-center mb-5 xl:mb-0 lg:mb-0 md:mb-0 sm:mb-0">
					<img src={fresh} alt="" className="xl:w-3/6 lg:w-3/6 w-2/6" />
				</div>
				<div className="flex justify-center items-center">
					<img src={ekiti} alt="" className="xl:w-3/6 lg:w-3/6 w-2/6" />
				</div>
				<div className="flex justify-center items-center">
					<img src={oldLagos} alt=""  />
				</div>
			</div>

				{/*follow on socials section*/}
				<div className="grid grid-cols-1 text-center py-20 px-4 hover:bg-custom-dark-gray-bg transition">
					<h1 
						className="
							xl:text-4xl lg:text-4xl md:text-3xl text-2xl tracking-[.25em] uppercase mb-5 
							text-white">
							follow us on instagram
					</h1>
					<p className="mb-2 text-white italic text-xl">
						<a href="https://www.instagram.com/glintzweddingphotography?igsh=MTJibTkyZzhjczljeg==">@glintzweddingphotography</a></p>
					<p className="text-white italic text-xl">
						<a href="https://www.instagram.com/glintz_studios/">@glintz_studios</a></p>
				</div>

			</div>
			<Footer />
		</>
	)
}

export default About