import React, {useState} from 'react'
import {Helmet} from "react-helmet";
import Navbar from './components/Navbar'
import Footer from './components/Footer'

const Contact = () => {
	const [formData, setFormData] = useState({
	    fullName: '',
	    email: '',
	    subject: '',
	    message: '',
	});

	// const URL = import.meta.env.VITE_BASE_URL;

	const contactForm = async (e) => {
		e.preventDefault()
		try {
			const response = await fetch('https://glintz-serverss.vercel.app/contact', {
		        method: 'POST',
		        headers: {
		          'Content-Type': 'application/json',
        		},
        		body: JSON.stringify(formData),
    		});
			if (response.ok) {
	        console.log('Form submitted successfully');
	        alert('Form submitted successfully')
	        // Reset the form data after successful submission
	        setFormData({
	          fullName: '',
	          email: '',
	          subject: '',
	          message: '',
	        });
	      } else {
	        console.error('Form submission failed');
	      }
	    } catch (error) {
	      console.error('Error submitting form:', error);
	    }
	}

	const handleInputChange = (e) => {
	    const { name, value } = e.target;
	    setFormData((prevData) => ({
	      ...prevData,
	      [name]: value,
	    }));
	};

	return (
		<>
			<Helmet>
		    	<title>Glintz Photography - Contact | Wedding, Portrait, Documentary, Events, Storytelling</title>
			    <meta name="description" content="Swift Educational Consult about page" />
		    </Helmet>
			<Navbar />
			<div className="pb-20 xl:pt-40 lg:pt-40 md:pt-40 pt-[25px] bg-custom-light-bg" style={{backgroundImage:"url(https://solene.qodeinteractive.com/wp-content/uploads/2019/12/contact-2-bckgr-img.png)"}}>
				<div className="bg-white xl:mx-80 lg:mx-20 xl:px-[10%] lg:px-40 md:px-20 px-5 py-20">
					<div className="text-center uppercase mb-20">
						<h1 className="xl:text-[3em] lg:text-[2.5em] md:text-4xl text-3xl tracking-[.25em] uppercase text-custom-gray-head-text">get in touch</h1>
						<h3 className="xl:text-2xl lg:text-xl text-md tracking-wide uppercase mt-10 text-custom-gray-head-text">to hire us</h3>
					</div>

					<form onSubmit={contactForm}>
						<div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
							<div className="xl:mb-20 lg:mb-20 md:mb-20 mb-10">
								<input 
									className="
										border-solid 
										border-b 
										border-gray-500
										text-sm
										text-black
										xl:w-4/5 lg:w-4/5 md:w-4/5 w-full tracking-[.15em]
										focus:outline-none
										focus:border-black" 
									type="text" 
									name="fullName"
									value={formData.fullName}
        							onChange={handleInputChange}
									placeholder="FULL NAME" 
									style={{fontFamily: "Muli"}}
									required
								/>
							</div>
							<div className="xl:mb-0 lg:mb-0 md:mb-0 mb-10">
								<input 
									className="
										border-solid 
										border-b 
										border-gray-500
										text-sm
										xl:w-4/5 lg:w-4/5 md:w-4/5 w-full tracking-[.15em]
										focus:outline-none
										focus:border-black" 
									type="text"
									name="email"
									value={formData.email}
        							onChange={handleInputChange}
									placeholder="E-MAIL"
									style={{fontFamily: "Muli"}}
									pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
									required
								/>
								{formData.email.length > 0 && !formData.email.match("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}") && (
									<span className="text-red-500 text-sm">Invalid email format</span>
								)}
							</div>
							<div>
								<input className="
										border-solid 
										border-b 
										border-gray-500
										text-sm
										xl:w-4/5 lg:w-4/5 md:w-4/5 w-full tracking-[.15em]
										focus:outline-none
										focus:border-black"
									type="text" 
									name="subject"
									value={formData.subject}
        							onChange={handleInputChange}
									placeholder="SUBJECT" 
									style={{fontFamily: "Muli"}}
									required
								/>
							</div>

						</div>

						<textarea
					      id="message"
					      rows="4"
					      className="
					      	mt-20 focus:ring-blue-500 focus:border-blue-500 block w-full h-[200px] 
					      	shadow-sm sm:text-sm border-b border-gray-500 focus:outline-none focus:border-black"
					      placeholder="TELL US MORE"
					      name="message"
					      value={formData.message}
        				  onChange={handleInputChange}
					      style={{fontFamily: "Muli"}}
					      required
					    ></textarea>
						<div className="mt-10 flex justify-center">
							<button className="bg-custom-dark-gray-bg px-20 py-3 tracking-[.25em] text-sm text-white uppercase">send</button>
						</div>
					</form>
				</div>
				<div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 xl:px-80 px-10 pt-20 flex justify-center">
					<div className="text-center mb-5 xl:mb-0 lg:mb-0 md:mb-5 sm:mb-5">
						{/*<h2 className="uppercase xl:text-2xl lg:text-2xl md:text-2xl text-xl text-custom-gray-head-text">email</h2>*/}
						<div className="flex justify-center items-center">
							<img width="44" height="44" src="https://img.icons8.com/glyph-neue/44/new-post.png" alt="new-post"/>
						</div>
						<p><a className="text-custom-gray-text" style={{fontFamily: "Muli"}} href="mailto:hello@glintzphotography.org">hello@glintzphotography.org</a></p>
					</div>
					<div className="text-center mb-5 xl:mb-0 lg:mb-0 md:mb-5 sm:mb-5">
						{/*<h2 className="uppercase xl:text-2xl lg:text-2xl md:text-2xl text-xl text-custom-gray-head-text">address</h2>*/}
						<div className="flex justify-center items-center">
							<img width="44" height="44" src="https://img.icons8.com/ios-filled/44/address--v1.png" alt="address--v1"/>
						</div>
						<p className="text-custom-gray-text capitalize" style={{fontFamily: "Muli"}}>lagos, nigeria</p>
					</div>
					<div className="text-center mb-5 xl:mb-0 lg:mb-0 md:mb-0 sm:mb-5">
						{/*<h2 className="uppercase xl:text-2xl lg:text-2xl md:text-2xl text-xl text-custom-gray-head-text">call me</h2>*/}
						<div className="flex justify-center items-center">
							<img width="44" height="44" src="https://img.icons8.com/ios/44/phone--v1.png" alt="phone--v1"/>
						</div>
						<p><a className="text-custom-gray-text" style={{fontFamily: "Muli"}} href="tel:+1234567890">+234 907 878 6586</a></p>
					</div>
					<div className="text-center">
						{/*<h2 className="uppercase xl:text-2xl lg:text-2xl md:text-2xl text-xl text-custom-gray-head-text">follow</h2>*/}
						<div className="flex justify-center items-center">
							<img width="44" height="44" src="https://img.icons8.com/ios/44/instagram-new--v1.png" alt="instagram-new--v1"/>
						</div>
						<p><a className="text-custom-gray-text" href="https://instagram.com/glintzweddingphotography" target="_blank" style={{fontFamily: "Muli"}}>@glintzweddingphotography</a></p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default Contact