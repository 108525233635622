import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import Academy from "./pages/Academy";
import AcademyMore from "./pages/AcademyMore";
import Contact from './pages/Contact';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import PortfolioAll from './pages/PortfolioAll';
import Investment from './pages/Investment';
import HomeBlog from './pages/Blog';
import Blog from './pages/admin/Blog';
import PostBlog from './pages/admin/PostBlog';
import EditBlog from './pages/admin/EditBlog';
import Register from './pages/admin/Register';
import AdminContact from './pages/admin/Contact';
import AdminAcademy from './pages/admin/Academy';
import Subscribers from './pages/admin/Subscribers';
import Brand from './pages/admin/BrandForm';
import EditBrand from './pages/admin/EditBrandForm';
import SubBrand from './pages/admin/SubBrandForm';
import ImageUploadForm from './pages/admin/ImageUploadForm';
import SubbrandImageUpload from './pages/admin/SubbrandImageUpload';
import AdminPortfolio from './pages/admin/Portfolio';
import Login from './pages/admin/Login';
import Home from './pages/admin/Index';
import CreatePost from './pages/admin/CreatePost';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    return (
        <>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/academy" element={<Academy />} />
              <Route path="/academy-learn" element={<AcademyMore />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/portfolio" element={<PortfolioAll />} />
              <Route path="/investment" element={<Investment />} />
              <Route path="/portfolio/:brandName" element={<Portfolio />} />
              <Route path="/blog/:id" element={<HomeBlog />} />
              <Route path="/admin/blog" element={<Blog />} />
              <Route path="/admin/blog/:id" element={<PostBlog />} />
              <Route path="/admin/edit/:id" element={<EditBlog />} />
              <Route path="/admin/register" element={<Register />} />
              <Route path="/admin/contact" element={<AdminContact />} />
              <Route path="/admin/academy" element={<AdminAcademy />} />
              <Route path="/admin/subscribers" element={<Subscribers />} />
              <Route path="/admin/brand" element={<Brand />} />
              <Route path="/admin/editbrand" element={<EditBrand />} />
              <Route path="/admin/subbrand" element={<SubBrand />} />
              <Route path="/admin/upload-to-portfolio" element={<ImageUploadForm />} />
              <Route path="/admin/upload-to-subportfolio" element={<SubbrandImageUpload />} />
              <Route path="/admin/portfolio/:id" element={<AdminPortfolio />} />
              <Route path="/admin/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
              <Route path="/admin/home" element={<Home />} />
              <Route path="/admin/create" element={<CreatePost />} />
            </Routes>
          </BrowserRouter>
            
        </>
    );
};

export default App;