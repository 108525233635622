import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet";
import { useParams, Link } from 'react-router-dom'
import portrait from '../assets/home/portraits2.jpg'
import Navbar from './components/Navbar'
import Footer from './components/Footer'

const Blog = () => {
	const [postInfo, setPostInfo] = useState(null)
	const {id} = useParams()

	// const URL = import.meta.env.VITE_BASE_URL;

	useEffect(() => {
		fetch(`https://glintz-serverss.vercel.app/post/${id}`)
		.then(response => {
			response.json().then(postInfo => {
				setPostInfo(postInfo)
			})
		})
	}, [])

	if(!postInfo) return ''

	return (
		<>
			<Helmet>
		    	<title>Glintz Photography - Blog | Wedding, Portrait, Documentary, Events, Storytelling</title>
			    <meta name="description" content="Glintz Photography blog page" />
		    </Helmet>
			<Navbar />
			<div className="pt-[100px]">
				<div className="grid grid-cols-2 h-28 xl:px-44 px-2 pt-10 " 
					style={{backgroundImage:"url(https://glintzphotography.org/images/academy-header.jpg)", 
							backgroundSize: "100%",
						    backgroundPosition: "center center"}}>
					<div>
						<p className="text-white text-2xl uppercase tracking-[.15em]">blog</p>
					</div>
					<div>
						<p className="text-white float-right italic text-lg"><Link to="/">Home</Link> / blog</p>
					</div>
				</div>
			</div>
			<div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 xl:px-40 lg:px-40 md:px-20 px-5 xl:py-20 lg:py-20 md:py-10 py-5">
				<div>
					<img src={postInfo.cover} alt="" className="xl:w-5/6 lg:w-5/6 md:w-4/5 w-2/5" />
				</div>
			</div>
			<div className="py-5 xl:px-80 lg:px-[70px] md:px-[70px] px-5">
				<h1 className="uppercase tracking-[.15em] text-center xl:text-4xl lg:text-4xl md:text-3xl text-2xl text-custom-gray-head-text mb-5">{postInfo.title}</h1>
				<p className="text-md text-custom-gray-text text-center" style={{fontFamily: "Muli"}}>
					<div dangerouslySetInnerHTML={{__html:postInfo.content}} />
				</p>
			</div>
			
			{/*follow us on socials*/}
			<div className="grid grid-cols-1 text-center py-20">
				<h1 
					className="
						xl:text-4xl lg:text-4xl md:text-3xl text-2xl tracking-[.25em] uppercase mb-5 
						text-custom-gray-head-text">
						follow us on instagram
				</h1>
				<p className="mb-2 text-custom-gray-text italic text-xl">
					<a href="https://www.instagram.com/glintzweddingphotography?igsh=MTJibTkyZzhjczljeg==/">@glintzweddingphotography</a></p>
				<p className="text-custom-gray-text italic text-xl">
					<a href="https://www.instagram.com/glintz_studios">@glintz_studios</a></p>
			</div>
			<Footer />
		</>
	)
}

export default Blog