import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'
// import Modal from 'react-modal';
// import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
// import photo1 from '../assets/home/portraits2.jpg'
// import photo2 from '../assets/home/portraits3.jpg'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import portrait from '../assets/home/portraits6.jpg'
import wedding from '../assets/home/wedding5.jpg'
import documentary from '../assets/home/documentary.jpg'
import event from '../assets/home/event1.jpg'
import commercial from '../assets/home/commercial.jpg'
import '../style.css'

const PortfolioAll = () => {

	// const [modalIsOpen, setModalIsOpen] = useState(false);
	// const [selectedImageIndex, setSelectedImageIndex] = useState(0);
	const [images, setImages] = useState([]);

	// const URL = import.meta.env.VITE_BASE_URL;
	// const API_URL = process.env.URL

  useEffect(() => {
    fetchImages();
  }, []);
  
  const fetchImages = async () => {
    try {
      const response = await fetch('https://glintz-serverss.vercel.app/upload/');
      if (response.ok) {
        const data = await response.json();
        setImages(
          data.map((image) => ({
            original: image.image,
            thumbnail: image.image,
          }))
        );
      } else {
        console.error('Error fetching images');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  


	// const openModal = (index) => {
  //   setSelectedImageIndex(index);
	//   setModalIsOpen(true);
	// };

  // const closeModal = () => {
  //   setModalIsOpen(false);
	// };

	return (
		<>
			<Helmet>
		    <title>Glintz Photography - Portfolio | Wedding, Portrait, Documentary, Events, Storytelling</title>
			  <meta name="description" content="Glintz Photography portfolio page" />
		  </Helmet>
			<Navbar />
			<div className="pt-[10px]">
				<div className="grid grid-cols-2 h-28 xl:px-44 px-10 pt-10 " 
					style={{backgroundImage:"url(https://glintzphotography.org/images/academy-header.jpg)", 
							backgroundSize: "100%",
						    backgroundPosition: "center center"}}>
					<div>
						<p className="text-white xl:text-2xl lg:text-2xl md:text-2xl text-xl uppercase tracking-[.15em]">portfolio</p>
					</div>
					<div>
						<p className="text-white float-right italic text-lg"><Link to="/">Home</Link> / <span className="capitalize">portfolio</span></p>
					</div>
				</div>

				<div className="mt-10 px-4 pb-[20px]">
					<div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-x-4 gap-y-4">
						<div className="relative">
							<a href="/portfolio/portrait" className="block relative">
								<img src={portrait} alt="portrait" className="w-full" />
								<div className="absolute inset-0 bg-black opacity-50"></div>
								<h3 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl text-white uppercase mt-4 font-semibold">portrait</h3>
							</a>
						</div>
						<div className="relative">
							<a href="/portfolio/wedding">
								<img src={wedding} alt="wedding" className="w-full" />
								<div className="absolute inset-0 bg-black opacity-50"></div>								
								<h3 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl text-white uppercase mt-4 font-semibold">wedding</h3>
							</a>
						</div>
						<div className="relative">
							<a href="/portfolio/documentary">
								<img src={documentary} alt="documentary" className="w-full" />
								<div className="absolute inset-0 bg-black opacity-50"></div>
								<h3 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl text-white uppercase mt-4 font-semibold">documentary</h3>
							</a>
						</div>
						<div className="relative">
							<a href="/portfolio/event">
								<img src={event} alt="event" className="w-full" />
								<div className="absolute inset-0 bg-black opacity-50"></div>
								<h3 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl text-white uppercase mt-4 font-semibold">event</h3>
							</a>
						</div>
						<div className="relative">
							<a href="/portfolio/commercial">
								<img src={commercial} alt="commercial" className="w-full" />
								<div className="absolute inset-0 bg-black opacity-50"></div>
								<h3 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl text-white uppercase mt-4 font-semibold">commercial</h3>
							</a>
						</div>
					</div>
				</div>

				{/*<div className="mt-20">
					<div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:px-[20%] lg:px-[20%] md:px-[10%] px-10 gap-20">
						<div className="text-center">
							<a href="/portfolio/portrait">
								<img src={portrait} alt="portraits" />
								<h3 className="text-4xl capitalize mt-4 font-semibold">portraits</h3>
								<p className="uppercase mt-4">the reminiscence</p>
								<div className="flex justify-center items-center">
									<img width="54" height="54" src="https://img.icons8.com/laces/54/arrow.png" alt="arrow"/>
								</div>
							</a>
						</div>
						<div className="text-center">
							<a href="/portfolio/wedding">
								<img src={wedding} alt="portraits" />
								<h3 className="text-4xl capitalize mt-4 font-semibold">weddings</h3>
								<p className="uppercase mt-4">the big day</p>
								<div className="flex justify-center items-center">
									<img width="54" height="54" src="https://img.icons8.com/laces/54/arrow.png" alt="arrow"/>
								</div>
							</a>
						</div>
						<div className="text-center">
							<a href="/portfolio/documentary">
								<img src={documentary} alt="portraits" />
								<h3 className="text-4xl capitalize mt-4 font-semibold">documentary</h3>
								<p className="uppercase mt-4">people | places | culture</p>
								<div className="flex justify-center items-center">
									<img width="54" height="54" src="https://img.icons8.com/laces/54/arrow.png" alt="arrow"/>
								</div>
							</a>
						</div>
						<div className="text-center">
							<a href="/portfolio/events">
								<img src={event} alt="portraits" />
								<h3 className="text-4xl capitalize mt-4 font-semibold">events</h3>
								<p className="uppercase mt-4">the moment</p>
								<div className="flex justify-center items-center">
									<img width="54" height="54" src="https://img.icons8.com/laces/54/arrow.png" alt="arrow"/>
								</div>
							</a>
						</div>
						<div className="text-center">
							<a href="/portfolio/commercial">
								<img src={commercial} alt="commercial" />
								<h3 className="text-4xl capitalize mt-4 font-semibold">commercial</h3>
								<p className="uppercase mt-4">the moment</p>
								<div className="flex justify-center items-center">
									<img width="54" height="54" src="https://img.icons8.com/laces/54/arrow.png" alt="arrow"/>
								</div>
							</a>
						</div>
					</div>
				</div>*/}


				<div className="grid grid-cols-1 text-center py-20 hover:bg-custom-dark-gray-bg transition">
					<h1 
						className="
							xl:text-4xl lg:text-4xl md:text-3xl text-2xl tracking-[.25em] uppercase mb-5 
							text-white">
							follow us on instagram
					</h1>
					<p className="mb-2 text-white italic text-xl">
						<a href="https://www.instagram.com/glintzweddingphotography?igsh=MTJibTkyZzhjczljeg==">@glintzweddingphotography</a>
					</p>
					<p className="text-white italic text-xl">
						<a href="https://instagram.com/glintz_studios">@glintz_studios</a>
					</p>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default PortfolioAll