import React, { useState } from 'react'

const Register = () => {

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	// const URL = import.meta.env.VITE_BASE_URL;

	async function register(e) {
	    e.preventDefault();
	    const response = await fetch('https://glintz-serverss.vercel.app/register', {
	      method: 'POST',
	      body: JSON.stringify({username,password}),
	      headers: {'Content-Type':'application/json'},
	    });
	    if (response.status === 200) {
	      alert('registration successful');
	    } else {
	      alert('registration failed');
	    }
	}

	return (
		<>
			<form onSubmit={register}>
				<input type="text" value={username} placeholder="username" onChange={e => setUsername(e.target.value)} />
				<input type="text" value={password} placeholder="password" onChange={e => setPassword(e.target.value)} />
				<button>Register</button>
			</form>
		</>
	)
}

export default Register