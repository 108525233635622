import React, { useState, useEffect, useRef } from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'
import documentary from '../assets/about/documentary.jpg'
import total from '../assets/about/total-logo.png'
import gordons from '../assets/about/gordons.png'
import fresh from '../assets/about/so-fresh.png'
import ekiti from '../assets/about/ekiti-logo.png'
import oldLagos from '../assets/about/old-lagos.png'
import leonard from '../assets/about/leonard.jpg'
import leonardGrayscale from '../assets/about/leonard-grayscale.jpg'
import fola from '../assets/about/fola.jpg'
import temitope from '../assets/home/tpj4s.jpg'
import moment from '../assets/home/moment.jpg'
import moment1 from '../assets/home/portraits2.jpg'
import moment2 from '../assets/home/portraits3.jpg'
import moment3 from '../assets/home/portraits6.jpg'
import hero from '../assets/home/hero.jpg'
import video from '../assets/home/video.jpg'
import section from '../assets/home/section.jpg'
// import moment4 from '../assets/home/portraits4.jpg'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import heroWedding from '../assets/home/portraits7.jpg'
import heroPrince from '../assets/home/portraits5.jpg'
import heroSignature from '../assets/home/signature-portrait.jpg'
import heroCouple from '../assets/home/wedding6.jpg'
import homeDocumentary from '../assets/home/documentary.jpg'
import homeWedding from '../assets/home/wedding4.jpg'
import homeEvent from '../assets/home/event1.jpg'
import slide1 from '../assets/home/slide-1.jpg'
import slide2 from '../assets/home/slide-2.jpg'
import slide3 from '../assets/home/slide-3.jpg'
import slide4 from '../assets/home/slide-4.jpg'
import slide5 from '../assets/home/slide-5.jpg'
import slide6 from '../assets/home/slide-6.jpg'
import slide7 from '../assets/home/slide-7.jpg'
import slide8 from '../assets/home/slide-8.jpg'
import slide9 from '../assets/home/slide-9.jpg'
import slide10 from '../assets/home/slide-10.jpg'
import slide11 from '../assets/home/slide-11.jpg'
import slide12 from '../assets/home/slide-12.jpg'
import slide13 from '../assets/home/slide-13.jpg'
import slide14 from '../assets/home/slide-14.jpg'
import slide15 from '../assets/home/slide-15.jpg'
import slide16 from '../assets/home/slide-16.jpg'
import slide17 from '../assets/home/slide-17.jpg'
import slide18 from '../assets/home/slide-18.jpg'
import slide19 from '../assets/home/slide-19.jpg'
import slide20 from '../assets/home/slide-20.jpg'
import slide21 from '../assets/home/slide-21.jpg'
import logo from '../assets/components/logo-black.png'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import '../style.css'
import Testimonial from './components/Testimonial'





const HomePage = () => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const [posts, setPosts] = useState([])
	const [formData, setFormData] = useState({
	    fullName: '',
	    email: '',
	    subject: '',
	    message: '',
	});
	const [subscribeData, setSubscribeData] = useState({
	    email: '',
	});

	// const URL = import.meta.env.VITE_BASE_URL;


	useEffect(() => {
	    fetch('https://glintz-serverss.vercel.app/post').then(response => {
    	  response.json().then(posts => {
        	setPosts(posts)
	      })
    	})
	}, [])

	const handleScroll = () => {
	    setScrollPosition(window.scrollY);
	};

	useEffect(() => {
	    window.addEventListener('scroll', handleScroll);
	    return () => {
	      window.removeEventListener('scroll', handleScroll);
	    };
	}, []);


	// hover section
	const triggerElements = document.querySelectorAll('.hover-trigger');
	const imagePlaceholders = document.querySelectorAll('.image-placeholder');

	triggerElements.forEach((triggerElement, index) => {
	  triggerElement.addEventListener('mouseenter', () => {
	    imagePlaceholders.forEach((imagePlaceholder, imageIndex) => {
	      if (imageIndex === index) {
	        imagePlaceholder.classList.remove('hidden');
	      } else {
	        imagePlaceholder.classList.add('hidden');
	      }
	    });
	  });
	});

	// Keep the currently displayed image when not hovering over any trigger
	imagePlaceholders.forEach((imagePlaceholder, imageIndex) => {
	  imagePlaceholder.addEventListener('mouseenter', () => {
	    triggerElements[imageIndex].classList.add('hover:underline');
	  });

	  imagePlaceholder.addEventListener('mouseleave', () => {
	    triggerElements[imageIndex].classList.remove('hover:underline');
	  });
	});




	// CONTACT FORM
	const contactForm = async (e) => {
		e.preventDefault()
		try {
			const response = await fetch('https://glintz-serverss.vercel.app/contact', {
		        method: 'POST',
		        headers: {
		          'Content-Type': 'application/json',
        		},
        		body: JSON.stringify(formData),
    		});
			if (response.ok) {
	        console.log('Form submitted successfully');
	        alert('Form submitted successfully')
	        // Reset the form data after successful submission
	        setFormData({
	          fullName: '',
	          email: '',
	          subject: '',
	          message: '',
	        });
	      } else {
	        console.error('Form submission failed');
	      }
	    } catch (error) {
	      console.error('Error submitting form:', error);
	    }
	}

	// SUBSCRIBE FORM
	const subscribeForm = async (e) => {
		e.preventDefault()
		try {
			const response = await fetch('https://glintz-serverss.vercel.app/subscribe', {
		        method: 'POST',
		        headers: {
		          'Content-Type': 'application/json',
        		},
        		body: JSON.stringify(subscribeData),
    		});
			if (response.ok) {
	        console.log('Form submitted successfully');
	        alert('You have successfully subscibed to our newsletter')
	        // Reset the form data after successful submission
	        setSubscribeData({
	          email: '',
	        });
	      } else {
	        console.error('Form submission failed');
	      }
	    } catch (error) {
	      console.error('Error submitting form:', error);
	    }
	}

	const handleInputChange = (e) => {
	    const { name, value } = e.target;
	    setFormData((prevData) => ({
	      ...prevData,
	      [name]: value,
	    }));
	};

	const handleInputChangeSubscribe = (e) => {
	    const { name, value } = e.target;
	    setSubscribeData((prevData) => ({
	      ...prevData,
	      [name]: value,
	    }));
	};




	return (
		<>
			<Helmet>
		    	<title>Glintz Photography - Home | Wedding, Portrait, Documentary, Events, Storytelling</title>
			    <meta name="description" content="Glintz Photography home page" />
		    </Helmet>
			<Navbar />
			{/*slider*/}
			<div className="xl:h-screen lg:h-screen md:h-screen h-[90%] pt-24 ">
				<Swiper
			        spaceBetween={30}
			        centeredSlides={true}
			        autoplay={{
			          delay: 2500,
			          disableOnInteraction: false,
			        }}
			        navigation={true}
			        modules={[Autoplay, Pagination, Navigation]}
			        className="mySwiper"
			    >
			        <SwiperSlide><img src={slide1} alt="SLIDE 1 WEDDING RECEPTION, DANCE, COUPLE DANCE, NIGERIAN WEDDING" /></SwiperSlide>
			        <SwiperSlide><img src={slide2} alt="SLIDE 2 WEDDING RECEPTION, DANCE, COUPLE DANCE, NIGERIAN WEDDING" /></SwiperSlide>
			        <SwiperSlide><img src={slide3} alt="SLIDE 3 PRE WEDDING PHOTOSHOOT, BEACH, NIGERIAN WEDDING" /></SwiperSlide>
			        <SwiperSlide><img src={slide4} alt="SLIDE 4 PRE WEDDING PHOTOSHOOT, BRIDE, GROOM, NIGERIAN WEDDING" /></SwiperSlide>
			        <SwiperSlide><img src={slide5} alt="SLIDE 5 CONCERT, SHOWS, CELEBRITY, PERFORMANCE" /></SwiperSlide>
			        <SwiperSlide><img src={slide6} alt="DOCUMENTARY, NATURE" /></SwiperSlide>
			        <SwiperSlide><img src={slide7} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide8} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide9} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide10} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide11} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide12} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide13} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide14} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide15} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide16} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide17} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide18} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide19} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide20} alt="" /></SwiperSlide>
			        <SwiperSlide><img src={slide21} alt="" /></SwiperSlide>
			    </Swiper>
			</div>

			{/*first section*/}
			<div className="
				grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1   md:gap-40 sm:gap-20 gap:20 
				xl:px-[200px] lg:px-[150px] md:px-[50px] sm:px-[100px] px-[50px] py-24 bg-custom-light-bg">
				<div className="">
					<h1 className="xl:text-[3.5em] lg:text-[3.5em] md:text-[3.5em] text-3xl uppercase tracking-[.15em] text-custom-gray-head-text">signature</h1>
					<p className="italic xl:text-2xl lg:text-2xl md:text-2xl text-xl w-4/5 mb-10 text-custom-gray-text">our style is clean, simple, and sincere to communicate essence</p>
					<div 
						className="xl:h-[700px] lg:h-[500px] md:h-[500px] sm:h-[600px] h-[400px]" 
						style={{
							overflow: "hidden",
							backgroundSize: 'cover',
    						backgroundPosition: 'center',
    						cursor: "pointer",
    					}}
    				>
    					<div
							style={{
								backgroundImage: `url(${heroCouple})`,
			                	backgroundSize: "cover",
	        		        	backgroundPosition: "center",
			                	height: "100%",
			                	width: "100%",
	        		        	transition: "transform 0.3s",
	        		       	}}
	        		       	onMouseEnter={(e) => {
							    e.currentTarget.style.transform = 'scale(1.1)'; // Zoom out on hover
							}}
							onMouseLeave={(e) => {
							    e.currentTarget.style.transform = 'scale(1)'; // Restore original size on hover out
							}} 
						></div>
        		    </div>
					<p className="uppercase text-sm tracking-[.15em] text-custom-gray-text mt-8">pre wedding</p>
					<h3 className="uppercase xl:text-2xl lg:text-2xl md:text-2xl text-xl 
						text-custom-gray-head-text tracking-[.15em] mb-10 xl:mb-0 lg:mb-0 md:mb-0">bridal bouquet</h3>
				</div>
				<div>
					<div 
						className="xl:h-[700px] lg:h-[500px] md:h-[500px] sm:h-[600px] h-[400px]" 
						style={{
							overflow: "hidden",
							backgroundSize: 'cover',
    						backgroundPosition: 'center',
    						cursor: "pointer",
    					}}
    				>
    					<div
							style={{
								backgroundImage: 'url(https://res.cloudinary.com/dfs540rt8/image/upload/v1692952613/portraits7_tv8kba.jpg)', 
			                	backgroundSize: "cover",
	        		        	backgroundPosition: "center",
			                	height: "100%",
			                	width: "100%",
	        		        	transition: "transform 0.3s",
	        		       	}}
	        		       	onMouseEnter={(e) => {
							    e.currentTarget.style.transform = 'scale(1.1)'; // Zoom out on hover
							}}
							onMouseLeave={(e) => {
							    e.currentTarget.style.transform = 'scale(1)'; // Restore original size on hover out
							}}
						></div>
        		    </div>
					<p className="uppercase text-sm tracking-[.15em] text-custom-gray-text mt-8">traditional wedding</p>
					<h3 className="uppercase xl:text-2xl lg:text-2xl md:text-2xl text-xl 
						text-custom-gray-head-text tracking-[.15em]">love and culture</h3>
				</div>
			</div>
			<div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 
				xl:px-[200px] lg:px-[150px] md:px-[50px] sm:px-[100px] px-[50px] gap-24 mb-20">
				<div>
					<div 
						className="xl:h-[700px] lg:h-[500px] md:h-[500px] sm:h-[600px] h-[400px]"
						style={{
							overflow: "hidden",
							backgroundSize: 'cover',
    						backgroundPosition: 'center',
    						cursor: "pointer",
    					}}
    				>
						<div 
							style={{
								backgroundImage: `url(${heroPrince})`,
			                	backgroundSize: "cover",
	        		        	backgroundPosition: "center",
	        		        	height: "100%",
			                	width: "100%",
	        		        	transition: "transform 0.3s",
	        		       	}} 
	        		       	onMouseEnter={(e) => {
								e.currentTarget.style.transform = 'scale(1.1)'; // Zoom out on hover
							}}
							onMouseLeave={(e) => {
							    e.currentTarget.style.transform = 'scale(1)'; // Restore original size on hover out
							}}
	        		   	></div>
	        		</div>
					<p className="uppercase text-sm tracking-[.15em] text-custom-gray-text mt-8">portrait</p>
					<h3 className="uppercase xl:text-2xl lg:text-2xl md:text-2xl text-xl 
						text-custom-gray-head-text tracking-[.15em]">royal prince</h3>
				</div>
				<div className="xl:-mt-[200px] lg:-mt-[200px] md:-mt-64">
					<div 
						className="xl:h-[700px] lg:h-[600px] md:h-[500px] sm:h-[600px] h-[400px]"
						style={{
							overflow: "hidden",
							backgroundSize: 'cover',
    						backgroundPosition: 'center',
    						cursor: "pointer",
    					}}
    				>
						<div
							style={{backgroundImage: `url(${hero})`, 
			                	backgroundSize: "cover",
	        		        	backgroundPosition: "center",
	        		        	height: "100%",
			                	width: "100%",
	        		        	transition: "transform 0.3s",
	        		        }} 
	        		        onMouseEnter={(e) => {
								e.currentTarget.style.transform = 'scale(1.1)'; // Zoom out on hover
							}}
							onMouseLeave={(e) => {
							    e.currentTarget.style.transform = 'scale(1)'; // Restore original size on hover out
							}}
	        		    ></div>
	        		</div>
					<p className="uppercase text-sm tracking-[.15em] text-custom-gray-text mt-8">portrait</p>
					<h3 className="uppercase xl:text-2xl lg:text-2xl md:text-2xl text-xl 
						text-custom-gray-head-text tracking-[.15em]">album cover</h3>
				</div>
			</div>


			{/*second section*/}
			<div className="bg-custom-light-bg">
				<div className="xl:h-[630px] md:h-[430px] grid grid-cols-1 mb-10 xl:px-40 lg:px-40 md:px-40 px-10 py-20">
					<h2 className="text-center uppercase text-xl xl:px-40 lg:px-40 md:px-20 text-custom-gray-head-text tracking-[.15em]">
						hiring us means you trust us to keep to our promise of delivering a remarkable and unforgettable memory of your project
					</h2>
				</div>
			</div>
			<div className="flex justify-center items-center xl:px-40 md:px-20 px-5 xl:pt-20 md:pt-80 xl:-mt-[500px] lg:-mt-[500px] md:-mt-[500px] -mt-[100px]">
				{/*<img src={video} alt="video" className="w-5/6"/>*/}
				<iframe
					className="xl:w-[100%] w-[100%] xl:h-[700px] lg:h-[700px] md:h-[700px] h-[400px]" 
					// width="1253" 
					// height="700" 
					src="https://www.youtube.com/embed/XeBd5ANLuvo?si=I-wMLvMOrz5Sm294&amp;controls=0" 
					title="YouTube video player" 
					frameBorder="0" 
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
					allowFullScreen
				>
				</iframe>
			</div>

			{/*team section*/}
			<div className="xl:px-80 xl:py-20 py-10 px-10">
				<div className="xl:py-14 py-10 text-center">
					<h1 className="xl:text-4xl lg:text-4xl md:text-3xl text-2xl uppercase text-custom-gray-head-text tracking-[.1em]">our photography team</h1>
					<p className="text-custom-gray-text italic text-xl">Meet our team</p>
				</div>
				<div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
					<div className="text-custom-gray-text">
						<img src={temitope} alt="Temitope" className="xl:mb-10 mb-5" />
						<p className="capitalize italic text-lg">creative director</p>
						<p className="uppercase tracking-[.15em]">temitope jalekun</p>
						<p className="uppercase">in<span>-</span><span>tw</span></p>
					</div>
					<div className="text-custom-gray-text">
						<img src={leonard} alt="leonard" className="xl:mb-10 mb-5" />
						<p className="capitalize italic text-lg">photographer</p>
						<p className="uppercase tracking-[.15em]">leonard ikediuba</p>
					</div>
					<div className="text-custom-gray-text">
						<img src={fola} alt="fola" className="xl:mb-10 mb-5" />
						<p className="capitalize italic text-lg">retoucher</p>
						<p className="uppercase tracking-[.15em]">fola</p>
					</div>
				</div>
			</div>

			<div className="xl:flex lg:flex md:flex grid grid-cols-1 xl:pl-10">
				<div className="my-auto order-2 xl:order-1 lg:order-1 md:order-1 px-10 xl:px-20 lg:px-0 md:px-0">
					<ul className="uppercase text-custom-gray-text tracking-[.15em] xl:text-4xl lg:text-4xl md:text-4xl text-2xl">
						<li className="py-4 hover:underline"><a href="/portfolio/portrait" className="hover-trigger" data-target="portrait">portrait</a></li>
						<li className="py-4 hover:underline"><a href="/portfolio/wedding" className="hover-trigger" data-target="wedding">wedding</a></li>
						<li className="py-4 hover:underline"><a href="/portfolio/events" className="hover-trigger" data-target="events">events</a></li>
						<li className="py-4 hover:underline"><a href="/portfolio/documentary" className="hover-trigger" data-target="documentary">documentary</a></li>
						<li className="py-4 hover:underline"><a href="/portfolio/commercial" className="hover-trigger" data-target="commercial">commercial</a></li>
					</ul>
				</div>
				<div className="order-1 xl:order-2 lg:order-2 md:order-2 px-10 xl:px-0 lg:px-0 md:px-0">
					<img src="https://res.cloudinary.com/dfs540rt8/image/upload/v1692952714/video_nsw9xp.jpg" 
						alt="portrait"
				      className="image-placeholder"
				      data-target="portrait"
				     />
					<img
				      src="https://res.cloudinary.com/dfs540rt8/image/upload/v1692952817/wedding4_mp1g9o.jpg"
				      alt="wedding"
				      className="hidden image-placeholder"
				      data-target="wedding"
				    />
				    <img
				      src="https://res.cloudinary.com/dfs540rt8/image/upload/v1692952668/event1_bbohgl.jpg"
				      alt="events"
				      className="hidden image-placeholder"
				      data-target="events"
				    />
				    <img
				      src="https://res.cloudinary.com/dfs540rt8/image/upload/v1692952709/documentary_ncypzu.jpg"
				      alt="documentary"
				      className="hidden image-placeholder"
				      data-target="documentary"
				    />
				    <img
				      src="https://res.cloudinary.com/dfs540rt8/image/upload/v1692952668/event1_bbohgl.jpg"
				      alt="commercial"
				      className="hidden image-placeholder"
				      data-target="commercial"
				    />

				</div>
			</div>


			{/*brands*/}
			<div className="xl:-mt-20 grid 
				xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-3 grid-cols-1 
				bg-custom-light-bg xl:py-40 lg:py-40 md:py-20 py-10 
				xl:px-64 lg:px-30 md:px-30 justify-center ">
				<div className="flex justify-center items-center mb-5 xl:mb-0 lg:mb-0 md:mb-0">
					<img src={total} alt="" className="xl:w-3/6 lg:w-3/6 w-2/6" />
				</div>
				<div className="flex justify-center items-center mb-5 xl:mb-0 lg:mb-0 md:mb-0">
					<img src={gordons} alt="" className="xl:w-3/6 lg:w-3/6 w-2/6" />
				</div>
				<div className="flex justify-center items-center mb-5 xl:mb-0 lg:mb-0 md:mb-0">
					<img src={fresh} alt="" className="xl:w-3/6 lg:w-3/6 w-2/6" />
				</div>
				<div className="flex justify-center items-center">
					<img src={ekiti} alt="" className="xl:w-3/6 lg:w-3/6 w-2/6" />
				</div>
				<div className="flex justify-center items-center">
					<img src={oldLagos} alt=""  />
				</div>
			</div>

			{/*blog*/}
			<div className="xl:px-40 lg:px-20 px-10 py-20">
				<div className="grid grid-cols-1 text-center mb-20">
					<h1 className="text-custom-gray-head-text xl:text-4xl lg:text-4xl md:text-3xl text-2xl tracking-[.15em] uppercase">read our blog</h1>
					<p className="text-custom-gray-text italic mt-2 text-xl">stories of love, people, places and culture</p>
				</div>
				<div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 gap-6">
					{posts.length > 0 && posts.slice(0, 4).map(post => (
						<div>
							<Link to={`/blog/${post._id}`}>
								<img src={post.cover} alt="" />
							</Link>
							<h2 className="uppercase tracking-[.15em] text-custom-gray-head-text text-xl font-semibold py-2">{post.title}</h2>
							<div dangerouslySetInnerHTML={{__html:post.content.slice(0, 120) + (post.content.length > 120 ? "..." : "")}} />

							<Link to={`/blog/${post._id}`}>
								<button className="py-1 mt-2 uppercase tracking-[.25em] border-none text-sm text-custom-gray-text hover:underline">
									read more
								</button>
							</Link>
						</div>
					))}
				</div>

			</div>

			{/*lets document section*/}
			<div className="xl:h-screen lg:h-[600px] md:h-[500px] h-[350px] flex justify-center items-center" 
				style={{
					backgroundImage: `url(${moment})`, 
					backgroundSize: "100%",
					backgroundRepeat: "no-repeat",
				    backgroundPosition: 'center center'
				}}
			>
				<h1 className="text-white xl:text-4xl lg:text-4xl md:text-3xl text-xl uppercase tracking-[.25em] text-center">
					let's document these moments together!
				</h1>
			</div>

			{/*testimonials*/}
			<div>
				<Testimonial />
			</div>

			{/*memory section*/}
			<div className="grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-2">
				<div className="relative group xl:h-[500px]">
					<img src="https://res.cloudinary.com/dfs540rt8/image/upload/v1692952729/portraits2_chzkjm.jpg" alt="" className="transition duration-300 ease-in-out transform group-hover:scale-105" />
					<div className="pb-5 absolute left-0 -bottom-44 w-full border-solid border-b border-white opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
    	    	  		<p className="text-white text-lg uppercase">memory</p>
    	    	  		<p className="text-2xl text-white uppercase">capture moment</p>
	    	    	</div>
				</div>
				<div className="relative group xl:h-[500px]">
					<img src="https://res.cloudinary.com/dfs540rt8/image/upload/v1692952730/portraits3_pcvv3m.jpg" alt="" className="transition duration-300 ease-in-out transform group-hover:scale-105" />
					<div className="pb-5 absolute left-0 -bottom-44 w-full border-solid border-b border-white opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
    	    	  		<p className="text-white text-lg uppercase">memory</p>
    	    	  		<p className="text-2xl text-white uppercase">now and forever</p>
	    	    	</div>
				</div>
				<div className="relative group xl:h-[500px] -mt-3 xl:mt-0 lg:mt-0 md:mt-0">
					<img src="https://res.cloudinary.com/dfs540rt8/image/upload/v1692952613/portraits7_tv8kba.jpg" alt="" className="transition duration-300 ease-in-out transform group-hover:scale-105" />
					<div className="pb-5 absolute left-0 -bottom-44 w-full border-solid border-b border-white opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
    	    	  		<p className="text-white text-lg uppercase">memory</p>
    	    	  		<p className="text-2xl text-white uppercase">let's celebrate</p>
	    	    	</div>
				</div>
				<div className="relative group xl:h-[500px]">
					<img src="https://res.cloudinary.com/dfs540rt8/image/upload/v1692952710/portraits6_lr6mal.jpg" alt="" className="transition duration-300 ease-in-out transform group-hover:scale-105" />
					<div className="pb-5 absolute left-0 -bottom-44 w-full border-solid border-b border-white opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
    	    	  		<p className="text-white text-lg uppercase">memory</p>
    	    	  		<p className="text-2xl text-white uppercase">birthday portrait</p>
	    	    	</div>
				</div>
			</div>
		
			{/*contact form*/}
			<div className="xl:py-40 xl:mt-[200px] lg:mt-[150px] md:mt-[150px] sm:mt-[80px] mt-[80px] mt-40 bg-white" style={{backgroundImage:"url(https://solene.qodeinteractive.com/wp-content/uploads/2019/12/contact-2-bckgr-img.png)"}}>
				<div className="bg-white">
					<div className="text-center uppercase mb-20">
						<h1 className="xl:text-[3em] lg:text-[2.5em] md:text-4xl text-2xl tracking-[.25em] uppercase text-custom-gray-head-text">get in touch</h1>
						<h3 className="xl:text-2xl lg:text-xl text-md tracking-wide uppercase mt-10 text-custom-gray-head-text">to hire us</h3>
					</div>

					<form onSubmit={contactForm} className="border-[1px] border-[#f4f0ed] xl:mx-40 lg:mx-20 xl:px-[5%] lg:px-40 md:px-40 px-5 py-20">
						<div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
							<div className="xl:mb-20 lg:mb-20 md:mb-20 mb-10">
								<input 
									className="
										border-solid 
										border-b 
										border-gray-500
										text-sm
										text-black
										xl:w-4/5 lg:w-4/5 md:w-4/5 w-full tracking-[.15em]
										focus:outline-none
										focus:border-black" 
									type="text" 
									placeholder="FULL NAME" 
									name="fullName"
									value={formData.fullName}
        							onChange={handleInputChange}
									style={{fontFamily: "Muli"}}
									required
								/>
							</div>
							<div>
								<input 
									className="
										border-solid 
										border-b 
										border-gray-500
										text-sm
										xl:w-4/5 lg:w-4/5 md:w-4/5 w-full tracking-[.15em]
										focus:outline-none
										focus:border-black" 
									type="text" 
									name="email"
									value={formData.email}
        							onChange={handleInputChange}
									placeholder="E-MAIL"
									pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
									style={{fontFamily: "Muli"}}
									required
								/>
							</div>
							<div className="xl:mt-0 lg:mt-0 md:mt-0 mt-10">
								<input className="
										border-solid 
										border-b 
										border-gray-500
										text-sm
										xl:w-4/5 lg:w-4/5 md:w-4/5 w-full tracking-[.15em]
										focus:outline-none
										focus:border-black"
									type="text" 
									name="subject"
									value={formData.subject}
        							onChange={handleInputChange}
									placeholder="SUBJECT" 
									style={{fontFamily: "Muli"}}
								/>
							</div>

						</div>

						<textarea
					      id="message"
					      rows="4"
					      className="tracking-[.15em] h-[200px]
					      	mt-20 focus:ring-blue-500 focus:border-blue-500 block w-full 
					      	shadow-sm sm:text-sm border-b border-gray-500 focus:outline-none focus:border-black"
					      placeholder="TELL US MORE"
					      name="message"
					      value={formData.message}
        				  onChange={handleInputChange}
					      style={{fontFamily: "Muli"}}
					      required
					    ></textarea>
						<div className="mt-10 flex justify-center">
							<button className="bg-custom-dark-gray-bg px-20 py-3 tracking-[.25em] text-sm text-white uppercase">send</button>
						</div>
					</form>
				</div>
			</div>

			{/*follow us on socials*/}
			<div className="grid grid-cols-1 text-center py-20 hover:bg-custom-dark-gray-bg transition">
				<h1 
					className="
						xl:text-4xl lg:text-4xl md:text-3xl text-2xl tracking-[.25em] uppercase mb-5 
						text-white">
						follow us on instagram
				</h1>
				<p className="mb-2 text-white italic text-xl"><a href="https://www.instagram.com/glintzweddingphotography/">@glintzweddingphotography</a></p>
				<p className="text-white italic text-xl"><a href="https://www.instagram.com/glintz_studios/">@glintz_studios</a></p>
			</div>

			<div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3  py-10">
				<div className="border-r px-10 py-20">
					<h3 className="text-2xl text-center uppercase tracking-[.15em] mb-2 text-custom-gray-text font-semibold">follow us</h3>
					<div className="flex justify-center space-x-8">
						<div>
							<a href="https://web.facebook.com/glintzmedia?mibextid=ZbWKwL"><img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/facebook-new.png" alt="facebook-new"/></a>
						</div>
						<div>
							<a href="https://www.instagram.com/glintzweddingphotography?igsh=MTJibTkyZzhjczljeg==">
								<img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/instagram-new.png" alt="instagram-new"/>
							</a>
						</div>
						<div>
							<a href="https://www.instagram.com/glintz_studios">
								<img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/instagram-new.png" alt="instagram-new"/>
							</a>
						</div>
						<div>
							<a href="https://x.com/glintzmedia?t=Lhsmk9m7viwv0muKKfviiQ&s=08"><img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/twitter--v1.png" alt="twitter--v1"/></a>
						</div>
						<div>
							<a href="https://www.youtube.com/channel/UCMdqsviB8dCvgYGTLPBgVaQ"><img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/youtube-play.png" alt="youtube-play"/></a>
						</div>
					</div>
				</div>
				<div className="flex justify-center py-20 border-r">
					<img src={logo} alt="logo" className="w-[50%] md:w-[30%] sm:w-[30%] h-[100%]" />
				</div>
				<div className="px-10 py-20">
					<div className="text-center ">
						<h3 className="uppercase text-2xl tracking-[.15em] font-semibold text-custom-gray-text">newsletter</h3>
						<p className="italic text-xl text-custom-gray-text">Follow our latest stories.</p>
					</div>
					<form className="text-center mt-2" onSubmit={subscribeForm}>
						<input 
							type="text" 
							placeholder="E-MAIL" 
							name="email" 
							value={subscribeData.email}
        					onChange={handleInputChangeSubscribe}
        					style={{fontFamily: "Muli"}}
							className="tracking-[.1em] border-b px-4 text-sm"
						/>
						<button	className="border-b px-2 mt-4 uppercase">submit</button>
					</form>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default HomePage