import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet";
// import image from "../assets/portraits6.jpg"
// import photoAcademy from '../assets/academy/academy.jpg'
// import offering from '../assets/academy/offering.jpg'
// import duration from '../assets/academy/duration-and-payment.jpg'
// import welcome from '../assets/academy/welcome.jpg'
// import header from '../assets/academy/academy-header.jpg'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import hero from '../assets/about/head-section.jpg'



const Academy = () => {
	const [subscribeData, setSubscribeData] = useState({
	    email: '',
	});


	// SUBSCRIBE FORM
	const subscribeForm = async (e) => {
		e.preventDefault()
		try {
			const response = await fetch('https://glintz-serverss.vercel.app/subscribe', {
		        method: 'POST',
		        headers: {
		          'Content-Type': 'application/json',
        		},
        		body: JSON.stringify(subscribeData),
    		});
			if (response.ok) {
	        console.log('Form submitted successfully');
	        alert('You have successfully subscibed to our newsletter')
	        // Reset the form data after successful submission
	        setSubscribeData({
	          email: '',
	        });
	      } else {
	        console.error('Form submission failed');
	      }
	    } catch (error) {
	      console.error('Error submitting form:', error);
	    }
	}

	const handleInputChangeSubscribe = (e) => {
	    const { name, value } = e.target;
	    setSubscribeData((prevData) => ({
	      ...prevData,
	      [name]: value,
	    }));
	};
	return (
		<>
		<Helmet>
	    	<title>Glintz Photography - Academy | Wedding, Portrait, Documentary, Events, Storytelling</title>
		    <meta name="description" content="Glintz Photography academy page" />
	    </Helmet>
		<Navbar />
		<div className=" pt-40 xl:pt-80 xl:px-20 lg:px-20 md:px-20 sm:px-10 px-5 relative" 
			style={{
				backgroundImage:`url(${hero})`, 
				backgroundSize: "cover", 
				backgroundPosition: "center", 
				backgroundRepeat: "no-repeat"
			}}
		>
			<div className="absolute inset-0 bg-black opacity-60"></div>
			<div className="py-40 text-center xl:px-[25%] lg:px-[25%] md:px-[20%] sm:px-[7%] px-[7%] relative">
				<h1 className="text-3xl uppercase text-white font-semibold tracking-[.15em] mb-4">glintz academy</h1>
				<p style={{fontFamily: "Muli"}} className="mb-4 capitalize text-white">Glintz Academy is a creative photography learning platform set up to educate and help improve photographer's lighting technicalities, posing, compostion, and business of photography know-how</p>
				{/*thumbnail*/}
				<p className="capitalize text-white" style={{fontFamily: "Muli"}}>over the years, we have groomed photographers in genres like; Wedding Photography, Portrait, and Commercial Photography.</p>
				<p className="mb-4 capitalize text-white" style={{fontFamily: "Muli"}}>in the learning process, our students will be able to interact with other industry leaders in Nigeria and will take on personal projects for the creation of their Portfolio.</p>
				<ul>
					<li className="text-white capitalize" style={{fontFamily: "Muli"}}>schedule: 3months class</li>
					<li className="text-white capitalize" style={{fontFamily: "Muli"}}>2-3 days weekly</li>
					<li className="text-white capitalize" style={{fontFamily: "Muli"}}>investment: N350,000</li>
				</ul>
			</div>
			<div className="text-center xl:px-[25%] lg:px-[25%] md:px-[20%] sm:px-[7%] px-[7%] relative">
			    <h1 className="text-3xl uppercase text-white font-semibold tracking-[.15em] mb-4">upgrade<span className="lowercase">me.</span></h1>
			    <p style={{fontFamily: "Muli"}} className="mb-4 capitalize text-white">This is a tailor-made one-on-one master class for intermediate and professional photographers. Here you have 
			    personal interaction, questions, and consultation specially customized for your photography brand.</p>
			    <button className="capitalize bg-custom-gray-bg px-10 py-4 text-white mt-8 transition hover:bg-custom-light-bg hover:text-black" style={{fontFamily: "Muli"}}>
			    	<Link to="/academy-learn" style={{fontFamily: "Muli"}}>learn more</Link></button>
			</div>


			<div className="mt-[100px] pb-20 text-center relative">
				<h1 className="text-3xl uppercase text-white font-semibold tracking-[.15em] mb-4">subscribe for email updates</h1>
				<p style={{fontFamily: "Muli"}} className="mb-4 capitalize text-white">join our email list to receive weekly inspirational videos and tutorials from Glintz.</p>
				<form className="text-center mt-8" onSubmit={subscribeForm}>
					<input 
						type="text" 
						placeholder="E-MAIL" 
						name="email" 
						value={subscribeData.email}
       					onChange={handleInputChangeSubscribe}
        				style={{fontFamily: "Muli"}}
						className="tracking-[.1em] border-b px-4 py-4 xl:w-2/6 lg:w-3/6 w-5/6 text-sm"
					/><br />
					<button	
						style={{fontFamily: "Muli"}}
						className=" mt-4 capitalize text-white bg-custom-gray-bg px-10 py-4 transition hover:bg-custom-light-bg hover:text-black">submit</button>
				</form>
			</div>
		</div>
		<Footer />
		</>
	)
}

export default Academy