import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'

const Portfolio = ({ match }) => {
  const [images, setImages] = useState([]);
  const {id} = useParams()

  // const URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await fetch('https://glintz-serverss.vercel.app/upload/${id}');
      if (response.ok) {
        const data = await response.json();
        setImages(data);
      } else {
        console.error('Error fetching images');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  return (
    <div>
      <h2>Images for {id} </h2>
      <div>
        {images.map((image) => (
          <div key={image._id}>
            <img src={`${URL}/${image.image}`} alt="" className="w-1/5" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;