import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Sidebar from './components/Sidebar'

const ImageUploadForm = () => {
  const [brands, setBrands] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);

  // const URL = import.meta.env.VITE_BASE_URL;


  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch('https://glintz-serverss.vercel.app/brands'); // Adjust the URL
        const data = await response.json();
        setBrands(data);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await fetch('https://glintz-serverss.vercel.app/upload');
      if (response.ok) {
        const data = await response.json();
        setImages(data);
      } else {
        console.error('Error fetching images');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleBrandSelect = (event) => {
    setSelectedBrandId(event.target.value);
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedBrandId) {
      alert('Please select a brand');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('image', image);

      const response = await fetch(`https://glintz-serverss.vercel.app/upload/${selectedBrandId}`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Image uploaded successfully');
        // Refresh the page after successful submission
        window.location.reload();
      } else {
        console.error('Error uploading image:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleDeletePortfolio = async (portfolioId) => {
    try {
      const response = await fetch(`https://glintz-serverss.vercel.app/upload/${portfolioId}`, { method: 'DELETE' });
      if (response.ok) {
        setImages(images.filter((image) => image._id !== portfolioId));
        alert('Image has been deleted successfully')
      } else {
        console.error('Error deleting brand:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting brand:', error);
    }
  };

  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="w-[85%]">
          <div className="px-5 py-4 bg-black text-white">
            <p className="capitalize text-lg" style={{fontFamily: "Muli"}}>welcome, admin</p>
          </div>
          <div className="p-10">
            <h2 className="text-4xl font-semibold mb-4 capitalize">Upload Image to Brand</h2>
            <select 
              className="mr-2 cursor-pointer" 
              style={{fontFamily: "Muli"}} 
              value={selectedBrandId} 
              onChange={handleBrandSelect}
            >
              <option 
                value="" 
                style={{fontFamily: "Muli"}}
              >Select a brand</option>
              {brands.map((brand) => (
                <option style={{fontFamily: "Muli"}} key={brand._id} value={brand._id}>
                  {brand.name}
                </option>
              ))}
            </select>
            <input 
              style={{fontFamily: "Muli"}} 
              type="file" 
              onChange={handleImageChange} 
              className="cursor-pointer"
            />
            <button 
              className="text-white bg-black px-10 py-4 uppercase" 
              style={{fontFamily: "Muli"}} 
              onClick={handleUpload}>
              Upload Image
            </button>
          </div>
          <div className="px-10 pb-10">
            <h2 className="text-4xl font-semibold mb-4 capitalize">Uploaded Images</h2>
            <div >
              <table className="min-w-full border border-gray-300 mt-10">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>s/n</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>portfolio</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>image</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>delete portfolio</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                  {images.map((image, index) => (
                    <tr className="hover:bg-gray-100" key={index}>
                      <td className="px-6 py-4 whitespace-nowrap" style={{fontFamily: "Muli"}}>{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap capitalize" style={{fontFamily: "Muli"}}>
                        <Link to={`/admin/portfolio/${image.brand}`} className="underline text-blue-500">
                          {image.brandName}
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap"><img src={image.image} alt="" className="w-1/5" /></td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button 
                          style={{fontFamily: "Muli"}}
                          className="bg-red-500 py-2 px-5 text-white font-semibold uppercase"
                          onClick={() => handleDeletePortfolio(image._id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageUploadForm;