import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar'
import EditBrandForm from './EditBrandForm'

const BrandForm = () => {
  const [brandName, setBrandName] = useState('');
  const [brandList, setBrandList] = useState([]);

  // const URL = import.meta.env.VITE_BASE_URL;

  const handleBrandSubmit = async () => {
    try {
      const response = await fetch('https://glintz-serverss.vercel.app/brands', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: brandName }),
      });

      if (response.ok) {
        const brand = await response.json();
        setBrandName('')
        alert('New portfolio successfully created')
        console.log('Brand created:', brand);

        // Refresh the page after successful submission
        window.location.reload();
      } else {
        console.error('Error creating brand:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating brand:', error);
    }
  };

  useEffect(() => {
    // Fetch data from the backend
    fetchData();
  }, []);

  const fetchData = async () => {
      try {
        const response = await fetch('https://glintz-serverss.vercel.app/brands'); // Replace with your backend endpoint
        if (response.ok) {
          const data = await response.json();
          setBrandList(data);
        } else {
          console.error('Error fetching data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  const handleBrandUpdated = (updatedBrand) => {
    const updatedBrands = brandList.map((brand) => (brand._id === updatedBrand._id ? updatedBrand : brand));
    setBrandList(updatedBrands);
  };

  const handleDeleteBrand = async (brandId) => {
    try {
      const response = await fetch(`https://glintz-serverss.vercel.app/brands/${brandId}`, { method: 'DELETE' });
      if (response.ok) {
        setBrandList(brandList.filter((brand) => brand._id !== brandId));
      } else {
        console.error('Error deleting brand:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting brand:', error);
    }
  };



  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="w-[85%]">
          <div className="px-5 py-4 bg-black text-white">
            <p className="capitalize text-lg" style={{fontFamily: "Muli"}}>welcome, admin</p>
          </div>
          <div className="p-20" style={{fontFamily: "Muli"}}>
            <h2 className="text-4xl font-semibold mb-4">Create Portfolio</h2>
            <input 
              className="border mr-4 pl-4 py-4 w-3/5"
              type="text" 
              placeholder="Portfolio name" 
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)} 
            />
            <button className="text-white bg-black px-10 py-4 uppercase" onClick={handleBrandSubmit}>Create</button>

            <div className="mt-5 mb-5">
              <h2 className="text-4xl font-semibold mb-4 capitalize">list of portfolios</h2>
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>s/n</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>portfolio</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>edit portfolio</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>delete portfolio</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                  {brandList.length > 0 && brandList.map((brand, index) => (
                    <tr className="hover:bg-gray-100" key={brand._id}>
                      <td className="px-6 py-4 whitespace-nowrap" style={{fontFamily: "Muli"}}>{index + 1}</td>
                      <td 
                        className="px-6 py-4 whitespace-nowrap capitalize" 
                        style={{fontFamily: "Muli"}}
                      >
                        {brand.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap"><EditBrandForm brandId={brand._id} onBrandUpdated={handleBrandUpdated} /></td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button 
                          style={{fontFamily: "Muli"}}
                          className="bg-red-500 py-2 px-5 text-white font-semibold uppercase" 
                          onClick={() => handleDeleteBrand(brand._id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandForm;
