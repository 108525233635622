import { useState } from 'react'
import {Navigate} from "react-router-dom";
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Sidebar from './components/Sidebar'
// import Editor from './Editor'

const modules = {
	toolbar: [
		[{'header': [1,2,false]}],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}],
		['link', 'image'],
		['clean']
	]}

const formats = [
	'header',
	'bold', 'italic', 'underline', 'strike', 'blockquote',
	'list', 'bullet', 'indent',
	'link', 'image'
]


export default function CreatePost() {
	const [title, setTitle] = useState('')
	const [summary, setSummary] = useState('')
	const [content, setContent] = useState('')
	const [files, setFiles] = useState('')
	const [redirect, setRedirect] = useState(false)

	// const URL = import.meta.env.VITE_BASE_URL;

	async function createNewPost(ev) {
		const data = new FormData()
		data.set('title', title)
		data.set('summary', summary)
		data.set('content', content)
		data.set('file', files[0])
		ev.preventDefault()
		const response = await fetch('https://glintz-serverss.vercel.app/post', {
			method: 'POST',
			body: data,
			credentials: 'include',
		})
		if(response.ok) {
			setRedirect(true)
			alert('blog post successfully created')
		}
	}

	if(redirect) {
		return <Navigate to={'/admin/blog'} />
	}


	return(
		<>
			<div className="flex">
		      <Sidebar />
		      <div className="w-[85%]">
		        <div className="px-5 py-4 bg-black text-white">
		          <p className="capitalize text-lg" style={{fontFamily: "Muli"}}>welcome, admin</p>
		        </div>
				<div className="px-20 py-20">
					<form onSubmit={createNewPost}>
						<input 
							className="border border-solid border-black px-5 py-3 w-[70%]"
							style={{fontFamily: "Muli"}}
							type="title" 
							placeholder={'Title'} 
							value={title} 
							onChange={ev => setTitle(ev.target.value)} /> <br /><br />
						<input 
							className="border border-solid border-black px-5 py-3 w-[70%]" 
							style={{fontFamily: "Muli"}}
							type="summary" 
							placeholder={'Summary'} 
							value={summary} 
							onChange={ev => setSummary(ev.target.value)} /><br /><br />
						<input 
							style={{fontFamily: "Muli"}}
							type="file" 
							onChange={ev => setFiles(ev.target.files)} /><br /><br />
						<ReactQuill  value={content} onChange={newValue => setContent(newValue)} module={modules} formats={formats} />
						{/*OR <Editor value={content} onChange={setContent} />*/}
						<button 
							className="mt-5 bg-black text-white px-5 py-3 uppercase text-sm transition hover:bg-gray-900" 
							style={{fontFamily: "Muli"}}>
							Create post
						</button>
					</form>
				</div>
			  </div>
			</div>
		</>
	)
}