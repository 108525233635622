import React, {useState} from 'react'
import {Helmet} from "react-helmet";
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import photoAcademy from '../assets/academy/academy.jpg'
import offering from '../assets/academy/offering.jpg'
import duration from '../assets/academy/duration-and-payment.jpg'
import welcome from '../assets/academy/welcome.jpg'
import header from '../assets/academy/academy-header.jpg'

const Investment = () => {
	const [formData, setFormData] = useState({
	    fullName: '',
	    email: '',
	    phoneNumber: '',
		location: '',
		gender: '',
		photoTraining: '',
		photographyFocus: '',
		sessionPayment: '',
	  	healthCondition: '',
	  	message: '',
	});
	const [subscribeData, setSubscribeData] = useState({
	    email: '',
	});

	// const URL = import.meta.env.VITE_BASE_URL;

	const academyForm = async (e) => {
		e.preventDefault()
		try {
			const response = await fetch('https://glintz-serverss.vercel.app/academy', {
		        method: 'POST',
		        headers: {
		          'Content-Type': 'application/json',
        		},
        		body: JSON.stringify(formData),
    		});
			if (response.ok) {
	        console.log('Form submitted successfully');
	        alert('Form submitted successfully')
	        // Reset the form data after successful submission
	        setFormData({
	          fullName: '',
		      email: '',
		      phoneNumber: '',
			  location: '',
			  gender: '',
			  photoTraining: '',
			  photographyFocus: '',
			  sessionPayment: '',
		  	  healthCondition: '',
		  	  message: '',
	        });
	      } else {
	        console.error('Form submission failed');
	      }
	    } catch (error) {
	      console.error('Error submitting form:', error);
	    }
	}

	// SUBSCRIBE FORM
	const subscribeForm = async (e) => {
		e.preventDefault()
		try {
			const response = await fetch('https://glintz-serverss.vercel.app/subscribe', {
		        method: 'POST',
		        headers: {
		          'Content-Type': 'application/json',
        		},
        		body: JSON.stringify(subscribeData),
    		});
			if (response.ok) {
	        console.log('Form submitted successfully');
	        alert('You have successfully subscibed to our newsletter')
	        // Reset the form data after successful submission
	        setSubscribeData({
	          email: '',
	        });
	      } else {
	        console.error('Form submission failed');
	      }
	    } catch (error) {
	      console.error('Error submitting form:', error);
	    }
	}

	const handleInputChange = (e) => {
	    const { name, value } = e.target;
	    setFormData((prevData) => ({
	      ...prevData,
	      [name]: value,
	    }));
	};

	const handleInputChangeSubscribe = (e) => {
	    const { name, value } = e.target;
	    setSubscribeData((prevData) => ({
	      ...prevData,
	      [name]: value,
	    }));
	};


	return (
		<>
			<Helmet>
		    	<title>Investment - Glintz Photography | Wedding, Portrait, Documentary, Events, Storytelling</title>
			    <meta name="description" content="Glintz Photography academy page" />
		    </Helmet>
			<Navbar />
			<div className="bg-custom-light-bg pt-20">
				<div className="h-[600px] py-20 hidden xl:block lg:block md:block ">
					<div className="h-full xl:mx-20 lg:mx-20 md:mx-10 mt-50 bg-center bg-no-repeat justify-center items-center flex" style={{backgroundImage:"url(https://glintzphotography.org/images/academy-header.jpg)"}}>
						<p className="text-[3.5em] capitalize text-white text-center">investment</p>
					</div>
				</div>

				<div 
					className="
						grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1
						xl:h-screen lg:h-[650px] 
						xl:px-[13%] lg:px-[10%] md:px-10 sm:px-5 px-10
						py-20 gap-4 relative"
				>
					<div className="flex justify-center 
						xl:block lg:block md::block sm::block 
						xl:justify-start lg:justify-start md:justify-start sm:justify-start">
						<div 
							className="
								xl:h-[750px] lg:h-[500px] md:h-[450px] sm:h-[450px] h-[450px]
								bg-custom-gray-bg w-2/6">
						</div>
						<img src={photoAcademy} alt="hello world" 
							className="absolute top-0 
							xl:mt-36 lg:mt-[120px] md:mt-[110px] sm:mt-[105px] mt-[105px]
							xl:left-[170px] lg:left-5/6 md:left-2/6 left-5/6
							lg:ml-20 md:ml-10 sm:ml-10
							xl:h-[600px] lg:h-[400px] md:h-[400px] sm:h-[400px] h-[400px]
							max-h-full" 
						/>
					</div>
					<div className="mx-auto my-auto xl:ml-12 lg:ml-12 md:ml-12">
						<h3 className="text-2xl uppercase text-custom-gray-head-text font-semibold tracking-[.15em]">glintz photo academy</h3>
						<p className="mt-2 text-custom-gray-text" style={{fontFamily: "Muli"}}>A platform set to help young people discover, polish and explore their creative talents through Visual art 
						( Still and Motion Pictures). Over the years, we have been able to groom several amazing photographers, now 
						doing great in their chosen genre of photography beyond storytelling. our students will learn business ethics, 
						set design, branding/packaging, financial management. Etc.</p>

						<p className="mt-2 text-custom-gray-text" style={{fontFamily: "Muli"}}>In the cause of the training, our students will have opportunity to meet and engage other high profile 
						Nigerian photographers. They will also be assigned a personal project for portfolio creation purpose.</p>
					</div>
				</div>

				{/*second*/}
				<div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 
					xl:h-[900px] lg:h-[650px] xl:px-[13%] lg:px-[10%] md:px-10 sm:px-5 py-20 gap-4">
					<div className="mx-auto my-auto ml-12 relative order-2 md:order-1 sm:order-1 lg:order-1 xl:order-1">
						<h3 className="text-2xl uppercase text-custom-gray-head-text tracking-[.15em] font-semibold">our offerings</h3>
						<p className="mt-2 text-custom-gray-text" style={{fontFamily: "Muli"}}>Our program is designed for starters and intermediate photographers.</p>
						<p className="text-custom-gray-text mb-4" style={{fontFamily: "Muli"}}>courses:</p>

						<ul className="capitalize text-custom-gray-text" style={{fontFamily: "Muli"}}>
							<li style={{fontFamily: "Muli"}}>camera mechanics and operation</li>
							<li style={{fontFamily: "Muli"}}>lighting</li>
							<li style={{fontFamily: "Muli"}}>composition</li>
							<li style={{fontFamily: "Muli"}}>genre of photography expose</li>
							<li style={{fontFamily: "Muli"}}>retouching (basic to high end)</li>
							<li style={{fontFamily: "Muli"}}>business of photography</li>
						</ul>
					</div>
					<div 
						className="order-1 md:order-2 sm:order-2 lg:order-2 xl:order-2
							relative flex justify-center 
							xl:block lg:block md::block sm::block 
							xl:justify-start lg:justify-start md:justify-start sm:justify-start">
						<div 
							className="
								xl:h-[650px] lg:h-[500px] md:h-[450px] sm:h-[450px] h-[450px] bg-custom-gray-bg w-2/6 float-right">
						</div>
						<img src={offering} alt="hello world" 
							className="absolute top-0 
								xl:h-[500px] lg:h-[400px] md:h-5/6 sm:h-5/6 h-5/6 
								xl:mt-20 lg:mt-10 md:mt-6 sm:mt-10 mt-10
								max-h-full" 
						/>
					</div>
				</div>

				{/*third*/}
				<div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1
					 xl:h-[550px] lg:h-[450px] xl:px-[13%] lg:px-20 md:px-10 sm:px-5 py-20 gap-4">
					<div className="relative flex justify-center xl:block lg:block md::block sm::block xl:justify-start lg:justify-start md:justify-start sm:justify-start">
						<div className="
							xl:h-[400px] lg:h-[300px] md:h-[300px] h-[400px] bg-custom-gray-bg w-2/6"></div>
						<img src={duration} 
							alt="hello world" 
							className="absolute top-0 
								xl:left-20 lg:left-20 md:left-10 sm:left-10 left-18
								xl:mt-20 lg:mt-8 md:mt-10 sm:mt-20 mt-10
								xl:w-5/6 lg:w-4/6 md:w-5/6 sm:w-[400px] w-4/5 max-h-full" 
						/>
					</div>
					<div className="mx-auto my-auto ml-12">
						<h3 className="text-2xl uppercase text-custom-gray-head-text font-semibold mb-4 tracking-[.15em]">duration and payment</h3>
						<ul className="text-custom-gray-text">
							<li style={{fontFamily: "Muli"}}>2 months, 3days a week. 2-3hrs. #180,000</li>
							<li style={{fontFamily: "Muli"}}>1 month, 3days a week. 3hrs. #150,000</li>
							<li style={{fontFamily: "Muli"}}>3days Upgrade Class #70,000</li>
						</ul>
						<p className="mt-2 text-custom-gray-text mb-4" style={{fontFamily: "Muli"}}>you can choose any of our 3 sessions in a year;</p>
						<ul className="text-custom-gray-text mb-4" style={{fontFamily: "Muli"}}>
							<li style={{fontFamily: "Muli"}}>February - April</li>
							<li style={{fontFamily: "Muli"}}>June - August</li>
							<li style={{fontFamily: "Muli"}}>October - December.</li>
						</ul>
						<p className="text-custom-gray-text mb-2" style={{fontFamily: "Muli"}}>Payment: we are open to 2 installment</p>
						<p className="text-custom-gray-text mb-2" style={{fontFamily: "Muli"}}>Acc. No: 0031631632 (Access Bank)</p>
						<p className="text-custom-gray-text" style={{fontFamily: "Muli"}}>Name: Glintz Media.</p>

					</div>
				</div>


				<div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1
					xl:h-full lg:h-[450px] xl:px-[13%] lg:px-[10%] md:px-10 sm:px-5 py-20 gap-4">
					<div className="mx-auto my-auto ml-12 relative order-2 md:order-1 sm:order-1 lg:order-1 xl:order-1">
						<h3 className="text-2xl uppercase text-custom-gray-head-text font-semibold tracking-[.15em]">welcome</h3>
						<p className="mt-2 text-custom-gray-text" style={{fontFamily: "Muli"}}>
						It will be a pleasure to welcome you to our academy! et's discover and explore your creative talent through visual art</p>
						<p className="text-custom-gray-text mb-4">courses:</p>
					</div>
					<div 
						className="relative flex justify-center 
							xl:block lg:block md::block sm::block 
							xl:justify-start lg:justify-start md:justify-start sm:justify-start
							order-1 md:order-2 sm:order-2 lg:order-2 xl:order-2">
						<div className="h-[350px] xl:h-[450px] lg:h-[350px] md:h-[260px] sm:h-[350px] bg-custom-gray-bg w-2/6 float-right"></div>
						<img src={welcome} alt="hello world" 
							className="absolute top-0 
							xl:-left-[18px] lg:left-2 md:left-10 sm:left-10
							mt-8 xl:mt-12 lg:mt-8 md:mt-8 sm:mt-[70px] 
							xl:w-fit lg:w-[400px] md:w-[300px] sm:w-[280px] w-[400px] 
							xl:h-[350px] z-10"
						/>
					</div>
				</div>


				<div className="py-20" style={{backgroundImage:"url(https://solene.qodeinteractive.com/wp-content/uploads/2019/12/contact-2-bckgr-img.png)"}}>
					<div className="bg-white xl:mx-40 lg:mx-20 xl:px-[10%] lg:px-40 md:px-40 px-5 py-20">
						<div className="text-center uppercase mb-10">
							<h1 className="xl:text-[3em] lg:text-[2.5em] md:text-4xl text-3xl tracking-[.25em] text-custom-gray-head-text">book a session</h1>
							<h3 className="xl:text-2xl lg:text-xl text-md tracking-wide text-custom-gray-text mt-5">kindly fill in with correct information</h3>
						</div>

						<form onSubmit={academyForm}>
							<div className="grid grid-cols-2">
								<div className="mb-20">
									<input 
										className="
											border-solid 
											border-b 
											border-gray-500
											w-4/5" 
										type="text" 
										name="fullName"
										value={formData.fullName}
	        							onChange={handleInputChange}
										placeholder="FULL NAME" 
										style={{fontFamily: "Muli"}}
										required
									/>
								</div>
								<div>
									<input 
										className="
											border-solid 
											border-b 
											border-gray-500
											w-4/5" 
										type="text" 
										name="email"
										value={formData.email}
	        							onChange={handleInputChange}
										placeholder="E-MAIL" 
										style={{fontFamily: "Muli"}}
									/>
								</div>
								<div>
									<input className="
											border-solid 
											border-b 
											border-gray-500
											w-4/5"
										type="text" 
										name="phoneNumber"
										value={formData.phoneNumber}
	        							onChange={handleInputChange}
										placeholder="PHONE NUMBER" 
										style={{fontFamily: "Muli"}}
										required
									/>
								</div>
								<div>
									<input className="
											border-solid 
											border-b 
											border-gray-500
											w-4/5"
										type="text" 
										name="location"
										value={formData.location}
	        							onChange={handleInputChange}
										placeholder="WHERE ARE YOU LOCATED" 
										style={{fontFamily: "Muli"}}
									/>
								</div>
							</div>

							<textarea
						      id="message"
						      name="message"
						      value={formData.message}
	        				  onChange={handleInputChange}
						      rows="4"
						      className="mt-20 focus:ring-blue-500 h-[200px] pt-5 pl-5
						      	focus:border-blue-500 block w-full shadow-sm sm:text-sm border-b border-gray-500"
						      placeholder="DO YOU HAVE ANY QUESTION FOR US"
						      style={{fontFamily: "Muli"}}
						    ></textarea>
							<div className="mt-10 flex justify-center">
								<button className="bg-custom-dark-gray-bg px-20 py-3 tracking-[.25em] text-sm text-white uppercase" style={{fontFamily: "Muli"}}>send</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			
		</>
	)
}

export default Investment