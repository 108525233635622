import React, { useState } from 'react';

const SubbrandEdit = ({ subbrandId, onBrandUpdated }) => {
  const [newName, setNewName] = useState('');

  // const URL = import.meta.env.VITE_BASE_URL;

  const handleEditSubmit = async () => {
    try {
      const response = await fetch(`https://glintz-serverss.vercel.app/subbrands/${subbrandId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newName }),
      });

      if (response.ok) {
        const updatedBrand = await response.json();
        onBrandUpdated(updatedBrand);

        // Clear the input field and show an alert
        setNewName('');
        alert('Sub Portfolio successfully updated');
      } else {
        console.error('Error updating subbrand:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating subbrand:', error);
    }
  };

  return (
    <div>
      <input 
        style={{fontFamily: "Muli"}}
        type="text" 
        placeholder="New sub brand name" 
        value={newName} 
        onChange={(e) => setNewName(e.target.value)} 
        className="border py-2 mr-4 px-2"
      />
      <button 
        style={{fontFamily: "Muli"}} 
        onClick={handleEditSubmit} 
        className="bg-blue-500 py-2 px-5 text-white font-semibold uppercase"
      >
        Update
      </button>
    </div>
  );
};

export default SubbrandEdit;