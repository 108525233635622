import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/components/logo-black.png'
import horizontal from '../../assets/home/horizontal.png'

const Navbar = () => {

	const [show, setShow] = useState(false)
	const [scrollColor, setScrollColor] = useState('bg-white');
	const [scrollShadow, setScrollShadow] = useState(false);
	// const [isOpen, setIsOpen] = useState(false)
  	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const [brands, setBrands] = useState([]);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
    	setIsOpen(!isOpen);
  	};
	// const URL = import.meta.env.VITE_BASE_URL;


  	useEffect(() => {
	    // Fetch the list of brands from the API
	    const fetchBrands = async () => {
	      try {
	        const response = await fetch('https://glintz-serverss.vercel.app/brands'); // Adjust the URL
	        const data = await response.json();
	        setBrands(data);
	      } catch (error) {
	        console.error('Error fetching brands:', error);
	      }
	    };

	    fetchBrands();
	}, []);

	const toggleMobileMenu = () => {
	    setShow(!show);
	};

	const handleButtonMouseEnter = () => {
	    setIsDropdownVisible(true);
	};

	const handleButtonMouseLeave = () => {
	    setIsDropdownVisible(false);
	};

	const handleDropdownMouseLeave = () => {
	    setIsDropdownVisible(false);
	};

	const handleScroll = () => {
	    const currentScroll = window.pageYOffset;
	    const navbarHeight = 64; // Adjust this value based on your navbar height

	    if (currentScroll >= navbarHeight) {
	      setScrollColor('bg-white'); // Change the color class to your desired color
	      setScrollShadow(true)
	    } else {
	      setScrollColor('bg-white');
	      setScrollShadow(false)
	    }
	};

	useEffect(() => {
	    window.addEventListener('scroll', handleScroll);

	    return () => {
	      window.removeEventListener('scroll', handleScroll);
	    };
	}, []);


	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

	const toggleDropdown = () => {
	    setIsDropdownOpen(!isDropdownOpen);
	};

	const toggleSubmenu = () => {
	    setIsSubmenuOpen(!isSubmenuOpen);
	};


	return (
		<>
			<nav className={`fixed xl:h-[100px] lg:h-[100px] md:h-[100px] h-[70px] 
				grid grid-cols-3 xl:px-10 lg:px-10 md:px-10 px-4 z-20 transition-colors duration-300 ${scrollColor} 
				${scrollShadow ? 'shadow-md' : ''}`} >{/*className="h-[100px] grid grid-cols-3 px-10"*/}
				<div className="my-auto">
					<a href="/">
						<img src={logo} alt="logo" className="xl:w-[25%] w-80 lg:w-[30%] md:w-[50%]" />
					</a>
				</div>
				<div className="my-auto mx-auto">
					<ul className="hidden xl:flex lg:flex md:flex space-x-8 uppercase" >
						<li className="text-sm tracking-[.15em]"><Link to="/" style={{fontFamily: "Muli"}}>home</Link></li>
						<li className="text-sm tracking-[.15em]"><Link to="/about" style={{fontFamily: "Muli"}}>about</Link></li>
						<li className="text-sm tracking-[.15em]" style={{fontFamily: "Muli"}} 
							onMouseEnter={handleButtonMouseEnter} >
							<Link to="/portfolio" style={{fontFamily: "Muli"}}>portfolio</Link>
						</li>
						<li className="text-sm tracking-[.15em]"><Link to="/academy" style={{fontFamily: "Muli"}}>academy</Link></li>
						<li className="text-sm tracking-[.15em]"><Link to="/contact" style={{fontFamily: "Muli"}}>contact</Link></li>
					</ul>
				</div>
				<div className="hidden xl:flex lg:flex md:flex justify-end my-auto">
					<div className="flex flex-col items-end cursor-pointer">
						<button className="flex flex-col" onClick={toggleMenu}>
							<span className="py-[0.5%] px-9 bg-red-500 mb-2"></span>
							<span className="py-[0.5%] px-9 bg-red-500 "></span>
						</button>

						<div className={`fixed top-0 right-0 h-full w-[30%] bg-white z-50 transition-transform duration-300 transform shadow-xl ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
							style={{ maxHeight: '100vh', overflowY: 'auto' }}>
					        {/* Close button */}
					         <img 
					         	className="absolute top-0 right-0 m-4 cursor-pointer" 
					         	width="30" 
					         	height="30" 
					         	onClick={toggleMenu} src="https://img.icons8.com/ios/30/delete-sign--v1.png" 
					         	alt="delete-sign--v1"/>
					        
					        
					        {/* Content of the sliding section */}
					        
					        <div className="xl:px-10 lg:px-10 md:px-5 xl:mt-40 lg:mt-40 md:mt-20 mt-10 mb-10">
					        	<p className="text-custom-gray-text myCustomFontText text-center xl:text-[16px] lg:text-lg md:text-base text-lg" style={{fontFamily: "Muli"}}>
						        	Glintz Photography is a multimedia Brand, set up to document, tell African stories of love, places, people and their 
									culture through creative visuals(still and motion pictures). Our storytelling expertise ranges from Portraits, Documentary 
									to Events (weddings, Corporate and concerts). Our style is clean, simple, genuine and composed to communicate essence. 
									We know every event is unique and it is always our delight to create exquisite, candid and creative content with real deep 
									sense of mood in raw emotions peculiar to each client. We look forward to starting a long and lasting 
									meaningful relationship with you. We are based in Lagos, Nigeria and we are available to travel within and outside Africa.
					        	</p>
					        </div>
					    </div>
					</div>
				</div>

				{/* Hide on desktop screens */}
				<div className="flex justify-end my-auto">
					<div className="flex flex-col items-end cursor-pointer lg:hidden xl:hidden md:hidden"> 
				        <button className="flex flex-col" onClick={toggleMobileMenu}>
				          <span className="py-[0.5%] px-9 bg-red-500 mb-2"></span>
				          <span className="py-[0.5%] px-9 bg-red-500 "></span>
				        </button>
				    </div>
			    </div>
			      
				{isDropdownVisible && (
			        <div className="absolute top-24 left-[42%] bg-white w-[170px] border shadow-md"
			        	onMouseLeave={handleDropdownMouseLeave}>
			        	<div className="p-2  pl-2">
						{brands.map((brand) => (
						  	<div className="relative" key={brand._id}>
				            	<div className="p-2 uppercase">
				            		<a href={`/portfolio/${brand.name}`}
				            			className="text-sm" 
				        	    		style={{fontFamily: "Muli"}}
				    	        		onMouseEnter={handleButtonMouseEnter}>
				            			{brand.name}
				            		</a>
				            	</div>
				            </div>
				        ))}
				        </div>
			        </div>
			    )}
				{show && 
			    	<div className="lg:hidden absolute md:mt-20 sm:mt-[70px] mt-14 w-full max-h-[100vh] shadow-lg bg-white overflow-auto" id="mobile-menu">
					    <div className="px-2 pt-2 pb-3 space-y-1">
					      <Link to="/"><a href="/" 
					      	className="text-custom-gray-text block px-3 py-2 rounded-md text-base font-medium capitalize">home</a>
					      </Link>
					      <Link to="/about"><a href="/about" 
					      	className="text-custom-gray-text block px-3 py-2 rounded-md text-base font-medium capitalize">about</a>
					      </Link>
					      <div className="lg:hidden relative mt-24 w-full">
						      	<a href="/portfolio"
						    	    onClick={toggleDropdown}
						        	className="text-custom-gray-text block px-3 py-2 rounded-md text-base font-medium capitalize"
						      	>
						        Portfolio
						        </a>
						      {isDropdownOpen && (
						        <div className="absolute top-12 right-0 w-full shadow-lg bg-white" id="mobile-menu" >
					      		{brands.map((brand) => (
						          <div className="px-2 pt-2 space-y-1" key={brand._id}>
						            <a href={`/portfolio/${brand.brand}`}
						            	className="block px-3 capitalize rounded-md text-base font-medium"
						            	onClick={toggleSubmenu}>
						              {brand.brandName}
						            </a>
					            	<div className="ml-4 capitalize">
					            		<Link 
					            			to="/" 
						            		className="block px-3 capitalize rounded-md text-base font-medium">
						            		{brand.name}
						            	</Link>
					            	</div>
						          </div>
						    	))}
						        </div>
						      )}
						  </div>
					      <Link to="/academy"><a href="/" 
					      	className="text-custom-gray-text block px-3 py-2 rounded-md text-base font-medium capitalize">academy</a>
					      </Link>
					      <Link to="/contact"><a href="/contact" 
					      	className="text-custom-gray-text block px-3 py-2 rounded-md text-base font-medium capitalize">Contact</a>
					      </Link>
					    </div>
					</div>
				}
			</nav>
		</>
	)
}

export default Navbar
