import React, { useState } from 'react'
import {Helmet} from "react-helmet";
import presentation from '../assets/academy/presentation.png'
import photoAcademy from '../assets/academy/academy.jpg'
import offering from '../assets/academy/offering.jpg'
import duration from '../assets/academy/duration-and-payment.jpg'
import welcome from '../assets/academy/welcome.jpg'
import header from '../assets/academy/academy-header.jpg'
import obasola from '../assets/academy/obasola.jpeg'
import susijane from '../assets/academy/susijane.jpg'
import fola from '../assets/academy/fola.jpg'

import first from '../assets/academy/guides/first.jpg'
import second from '../assets/academy/guides/second.jpg'
import third from '../assets/academy/guides/third.jpg'
import forth from '../assets/academy/guides/forth.jpg'
import fifth from '../assets/academy/guides/fifth.jpg'
import sixth from '../assets/academy/guides/sixth.jpg'
import seventh from '../assets/academy/guides/seventh.jpg'
import eighth from '../assets/academy/guides/eighth.jpg'
import Navbar from './components/Navbar'
import Footer from './components/Footer'

const AcademyMore = () => {
	const [formData, setFormData] = useState({
	    fullName: '',
	    email: '',
	    phoneNumber: '',
		location: '',
		gender: '',
		photoTraining: '',
		photographyFocus: '',
		sessionPayment: '',
	  	healthCondition: '',
	  	message: '',
	});
	const [subscribeData, setSubscribeData] = useState({
	    email: '',
	});

	// const URL = import.meta.env.VITE_BASE_URL;

	const academyForm = async (e) => {
		e.preventDefault()
		try {
			const response = await fetch('https://glintz-serverss.vercel.app/academy', {
		        method: 'POST',
		        headers: {
		          'Content-Type': 'application/json',
        		},
        		body: JSON.stringify(formData),
    		});
			if (response.ok) {
	        console.log('Form submitted successfully');
	        alert('Form submitted successfully')
	        // Reset the form data after successful submission
	        setFormData({
	          fullName: '',
		      email: '',
		      phoneNumber: '',
			  location: '',
			  gender: '',
			  photoTraining: '',
			  photographyFocus: '',
			  sessionPayment: '',
		  	  healthCondition: '',
		  	  message: '',
	        });
	      } else {
	        console.error('Form submission failed');
	      }
	    } catch (error) {
	      console.error('Error submitting form:', error);
	    }
	}

	// SUBSCRIBE FORM
	const subscribeForm = async (e) => {
		e.preventDefault()
		try {
			const response = await fetch('https://glintz-serverss.vercel.app/subscribe', {
		        method: 'POST',
		        headers: {
		          'Content-Type': 'application/json',
        		},
        		body: JSON.stringify(subscribeData),
    		});
			if (response.ok) {
	        console.log('Form submitted successfully');
	        alert('You have successfully subscibed to our newsletter')
	        // Reset the form data after successful submission
	        setSubscribeData({
	          email: '',
	        });
	      } else {
	        console.error('Form submission failed');
	      }
	    } catch (error) {
	      console.error('Error submitting form:', error);
	    }
	}

	const handleInputChange = (e) => {
	    const { name, value } = e.target;
	    setFormData((prevData) => ({
	      ...prevData,
	      [name]: value,
	    }));
	};

	const handleInputChangeSubscribe = (e) => {
	    const { name, value } = e.target;
	    setSubscribeData((prevData) => ({
	      ...prevData,
	      [name]: value,
	    }));
	};

	return (
		<>
			<Helmet>
		    	<title>Academy -Glintz Photography | Wedding, Portrait, Documentary, Events, Storytelling</title>
			    <meta name="description" content="Glintz Photography academy page" />
		    </Helmet>
			<Navbar />
			<div className="bg-custom-light-bg pt-20">

				<div className="h-[600px] py-20 hidden xl:block lg:block md:block ">
					<div className="h-full xl:mx-20 lg:mx-20 md:mx-10 mt-50 bg-center bg-no-repeat justify-center items-center flex" style={{backgroundImage:`url(${presentation})`}}>
						{/*<h1 className="text-[3.5em] uppercase text-white text-center">UPGRADE<span className="lowercase">me.</span></h1>*/}
					</div>
				</div>

				<div className="xl:px-[20%] px-10 xl:py-20 lg:py-20 md:py-20 py-10 text-center">
					<h3 className="xl:text-4xl lg:text-4xl md:text-3xl text-2xl uppercase text-custom-gray-head-text font-semibold tracking-[.15em] mb-10">our story</h3>
					<h5 className="text-custom-gray-text mb-5" style={{fontFamily: "Muli"}}>This is a tailor-made one-on-one master class for Intermediate, Beginner, and professional photographers. Here you have personal interaction, questions, and consultation specially customized for your photography brand.</h5>

					<p className="text-custom-gray-text mb-2" style={{fontFamily: "Muli"}}>Instead of spending years and resources on trial and error, save yourself from struggles by just investing undivided attention for 3days.</p>
					<p className="text-custom-gray-text" style={{fontFamily: "Muli"}}>UpgradeMe is a Specially crafted intensive one-on-one Photography Upgrade class, designed for upskilling professional, intermediate, and beginner photographers. It is a 3-day Practical session that will provide well-structured enlightenment, a sense of purpose plus direction for your craft, Lighting techniques, Proper planning for a photography session, Composition, Retouching, and the Business of Photography. In these 3 days, you will be on your journey to creating your unique style and positioning for profit.</p>
				</div>

				<div className="xl:px-[20%] px-10 xl:py-20 lg:py-20 md:py-20 py-10 text-center">
					<h3 className="xl:text-4xl lg:text-4xl md:text-3xl text-2xl uppercase text-custom-gray-head-text font-semibold tracking-[.15em] mb-10">guide</h3>

					{/*GALLERY*/}
					<div id="flexbox">
				      <div class="column">
					      <img src={first} alt="Image" width="100%" />
					      <img src={second} alt="Image" width="100%" />
					      {/*<img src={third} alt="Image" width="100%" />*/}
				      </div>
				      <div class="column">
					      
					      <img src={fifth} alt="Image" width="100%" />
					      <img src={sixth} alt="Image" width="100%" />
				      </div>
				      <div class="column">
					      <img src={seventh} alt="Image" width="100%" />
					      {/*<img src="https://docs.google.com/uc?export=download&id=1I-bDFOoYZ-XUZkwDxRJmtiR_7DGVn_Eb" alt="Image" width="100%" />*/}
					      <img src={third} alt="Image" width="100%" />
				      </div>
				      <div class="column">
					      <img src={eighth} alt="Image" width="100%" />
					      {/*<img src="https://docs.google.com/uc?export=download&id=1cfqbl28ezKPikIXApzZQOEUPwKm2AKrr" alt="Image" width="100%" />*/}
					      <img src={forth} alt="Image" width="100%" />
				      </div>     
				    </div>
				</div>

				<div className="xl:px-[10%] lg:px-20 px-10 xl:py-20 lg:py-20 md:py-20 py-10 text-center">
					<h3 className="xl:text-4xl lg:text-4xl md:text-3xl text-2xl uppercase text-custom-gray-head-text font-semibold tracking-[.15em] mb-10">hear from our alumni</h3>
					<div className="grid grid-cols-1 xl:grid-cols-3 xl:gap-8 lg:grid-cols-3 md:grid-cols-3">
						<div>
							<div className="flex justify-center items-center mb-4">
								<img src={obasola} alt="" width="50%" style={{borderRadius: "50%"}}/>
							</div>
							<p className="font-semibold text-xl capitalize">obasola</p>
							<p className="capitalize mb-2" style={{fontFamily: "Muli"}}>Canada</p>
							<p style={{fontFamily: "Muli"}}>Mr. Temitope Jalekun’s attention to detail is impeccable, he went out of his way to give me an amazing experience; with other opportunities beyond what I signed up for officially. </p>
						</div>
						<div className="mt-8 xl:mt-0 lg:mt-0 md:mt-0">
							<div className="flex justify-center items-center mb-4">
								<img src={susijane} alt="" width="50%" style={{borderRadius: "50%"}}/>
							</div>
							<p className="font-semibold text-xl capitalize">susijane</p>
							<p className="capitalize mb-2" style={{fontFamily: "Muli"}}>wolverhampton, UK</p>
							<p style={{fontFamily: "Muli"}}>Glintz Media trained me exclusively on all aspects of photography including interactions with different clients, negotiation and delivering a perfect job.</p>
						</div>
						<div className="mt-8 xl:mt-0 lg:mt-0 md:mt-0">
							<div className="flex justify-center items-center mb-4">
								<img src={fola} alt="" width="50%" style={{borderRadius: "50%"}}/>
							</div>
							<p className="font-semibold text-xl capitalize">fola</p>
							<p className="capitalize mb-2" style={{fontFamily: "Muli"}}>lagos, nigeria</p>
							<p style={{fontFamily: "Muli"}}>Everything I know today in photography from lighting, posture, composition, approaching and managing clients, and retouching, I learned it all at Glintz.</p>
						</div>
					</div>
				</div>

				<div className="xl:px-[20%] lg:px-20 px-10 xl:py-40 lg:py-40 md:py-20 py-10 text-center">
					<h3 className="xl:text-4xl lg:text-4xl md:text-3xl text-2xl uppercase text-custom-gray-head-text font-semibold tracking-[.15em] mb-10">how to apply</h3>
					{/*<p style={{fontFamily: "Muli"}}>We Open for class from April to June, so you can pick any week of the month from the 3rd week in April to June 2023. The earlier you subscribe, the better for you to start profiting from your investment. Click on subscribe and fill out the form and select your preferred date for your class.  Account details will be forwarded to you.</p>*/}
					<p style={{fontFamily: "Muli"}}>As an aspiring or a professional Photographer you can choose between two distinct learning tracks tailored to suit your needs and schedule. The first option 
					is an intensive three-day one-on-one class, providing personalized instruction and rapid skill development for those seeking a sondensed learning experience. Alternatively, individuals can opt for the intensive academy class, requiring dedication over three months with sessions 
					held two to three times a week. This option offers a structured learning environment for comprehensive training and portfolio building. By selecting the format that best suits your goals and availability, you can embark on a fulfilling educational journey at Glintz Photography Academy. By clicking the link below</p>
				</div>

				<div className="xl:px-[20%] lg:px-20 px-10 xl:py-40 lg:py-40 md:py-20 py-10 text-center">
					<h3 className="xl:text-4xl lg:text-4xl md:text-3xl text-2xl uppercase text-custom-gray-head-text font-semibold tracking-[.15em] mb-10">what you need</h3>
					<p className="capitalize" style={{fontFamily: "Muli"}}>if you have a laptop, camera and an open mind to learn and unlearn</p>
					{/*<form className="text-center mt-2" onSubmit={subscribeForm}>
						<input 
							type="text" 
							placeholder="E-MAIL" 
							name="email" 
							value={subscribeData.email}
        					onChange={handleInputChangeSubscribe}
        					style={{fontFamily: "Muli"}}
							className="tracking-[.1em] py-4 border-b px-4 text-sm xl:w-[70%] lg:w-[70%] md:w-[70%] w-[70%]"
						/>
						<button	className="border hover:bg-custom-dark-gray-bg hover:text-white transition px-2 py-4 mt-4 uppercase">submit</button>
					</form>*/}
					<div className="mt-5">
						<a href="https://forms.gle/QaoUm6kc4fVeNpQWA" target="_blank" className="border text-white bg-custom-dark-gray-bg hover:text-white transition px-6 py-4 mt-4 uppercase">apply</a>
					</div>
				</div>
			</div>

				{/*
				<div 
					className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1
						xl:h-screen lg:h-[650px] 
						xl:px-80 lg:px-20 md:px-10 sm:px-5 px-10
						py-20 gap-4 relative">
					<div className="flex justify-center 
						xl:block lg:block md::block sm::block 
						xl:justify-start lg:justify-start md:justify-start sm:justify-start">
						<div 
							className="
								xl:h-[850px] lg:h-[500px] md:h-[450px] sm:h-[450px] h-[450px]
								bg-custom-gray-bg w-2/6">
						</div>
						<img src={photoAcademy} alt="hello world" 
							className="xl:absolute lg:absolute md:absolute sm:absolute absolute top-0 
							xl:mt-36 lg:mt-[120px] md:mt-[110px] sm:mt-[105px] mt-[105px]
							xl:left-[300px] lg:left-5/6 md:left-2/6 left-5/6
							lg:ml-20 md:ml-10 sm:ml-10
							xl:h-[700px] lg:h-[400px] md:h-[400px] sm:h-[400px] h-[400px]
							xl:w-1/4 lg:w-2/6
							max-h-full" 
						/>
					</div>
					<div className="mx-auto my-auto xl:ml-12 lg:ml-12 md:ml-12">
						<h3 className="text-2xl uppercase text-custom-gray-head-text font-semibold tracking-[.15em]">glintz photo academy</h3>
						<p className="mt-2 text-custom-gray-text" style={{fontFamily: "Muli"}}>A platform set to help young people discover, polish and explore their creative talents through Visual art 
						( Still and Motion Pictures). Over the years, we have been able to groom several amazing photographers, now 
						doing great in their chosen genre of photography beyond storytelling. our students will learn business ethics, 
						set design, branding/packaging, financial management. Etc.</p>

						<p className="mt-2 text-custom-gray-text" style={{fontFamily: "Muli"}}>In the cause of the training, our students will have opportunity to meet and engage other high profile 
						Nigerian photographers. They will also be assigned a personal project for portfolio creation purpose.</p>
					</div>
				</div>


				<div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 
					xl:h-[900px] lg:h-[650px] xl:px-80 lg:px-20 md:px-10 sm:px-5 py-20 gap-4">
					<div className="mx-auto my-auto ml-12 relative order-2 md:order-1 sm:order-1 lg:order-1 xl:order-1">
						<h3 className="text-2xl uppercase text-custom-gray-head-text tracking-[.15em] font-semibold">our offerings</h3>
						<p className="mt-2 text-custom-gray-text" style={{fontFamily: "Muli"}}>Our program is designed for starters and intermediate photographers.</p>
						<p className="text-custom-gray-text mb-4" style={{fontFamily: "Muli"}}>courses:</p>

						<ul className="capitalize text-custom-gray-text" style={{fontFamily: "Muli"}}>
							<li style={{fontFamily: "Muli"}}>camera mechanics and operation</li>
							<li style={{fontFamily: "Muli"}}>lighting</li>
							<li style={{fontFamily: "Muli"}}>composition</li>
							<li style={{fontFamily: "Muli"}}>genre of photography expose</li>
							<li style={{fontFamily: "Muli"}}>retouching (basic to high end)</li>
							<li style={{fontFamily: "Muli"}}>business of photography</li>
						</ul>
					</div>
					<div 
						className="order-1 md:order-2 sm:order-2 lg:order-2 xl:order-2
							relative flex justify-center 
							xl:block lg:block md::block sm::block 
							xl:justify-start lg:justify-start md:justify-start sm:justify-start">
						<div 
							className="
								xl:h-[650px] lg:h-[500px] md:h-[450px] sm:h-[450px] h-[450px] bg-custom-gray-bg w-2/6 float-right">
						</div>
						<img src={offering} alt="hello world" 
							className="absolute top-0 left-15 sm:left-10 
								xl:h-[500px] lg:h-5/6 md:h-5/6 sm:h-5/6 h-5/6 
								xl:mt-20 lg:mt-10 md:mt-6 sm:mt-10 mt-10
								xl:w-4/5 lg:w-4/5 md:w-4/5 sm:w-4/5 w-5/6 max-h-full" />
					</div>
				</div>


				<div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1
					 xl:h-[700px] lg:h-[450px] xl:px-80 lg:px-20 md:px-10 sm:px-5 py-20 gap-4">
					<div className="relative flex justify-center xl:block lg:block md::block sm::block xl:justify-start lg:justify-start md:justify-start sm:justify-start">
						<div className="
							xl:h-[500px] lg:h-[300px] md:h-[300px] h-[400px] bg-custom-gray-bg w-2/6"></div>
						<img src={duration} 
							alt="hello world" 
							className="absolute top-0 
								xl:left-20 lg:left-20 md:left-10 sm:left-10 left-18
								xl:mt-20 lg:mt-8 md:mt-10 sm:mt-20 mt-10
								xl:w-5/6 lg:w-4/6 md:w-5/6 sm:w-[400px] w-4/5 max-h-full" 
						/>
					</div>
					<div className="mx-auto my-auto ml-12">
						<h3 className="text-2xl uppercase text-custom-gray-head-text font-semibold mb-4 tracking-[.15em]">duration and payment</h3>
						<ul className="text-custom-gray-text">
							<li style={{fontFamily: "Muli"}}>2 months, 3days a week. 2-3hrs. #180,000</li>
							<li style={{fontFamily: "Muli"}}>1 month, 3days a week. 3hrs. #150,000</li>
							<li style={{fontFamily: "Muli"}}>3days Upgrade Class #70,000</li>
						</ul>
						<p className="mt-2 text-custom-gray-text mb-4" style={{fontFamily: "Muli"}}>you can choose any of our 3 sessions in a year;</p>
						<ul className="text-custom-gray-text mb-4" style={{fontFamily: "Muli"}}>
							<li style={{fontFamily: "Muli"}}>February - April</li>
							<li style={{fontFamily: "Muli"}}>June - August</li>
							<li style={{fontFamily: "Muli"}}>October - December.</li>
						</ul>
						<p className="text-custom-gray-text mb-2" style={{fontFamily: "Muli"}}>Payment: we are open to 2 installment</p>
						<p className="text-custom-gray-text mb-2" style={{fontFamily: "Muli"}}>Acc. No: 0031631632 (Access Bank)</p>
						<p className="text-custom-gray-text" style={{fontFamily: "Muli"}}>Name: Glintz Media.</p>

					</div>
				</div>


				<div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1
					xl:h-full lg:h-[450px] xl:px-80 lg:px-20 md:px-10 sm:px-5 py-20 gap-4">
					<div className="mx-auto my-auto ml-12 relative order-2 md:order-1 sm:order-1 lg:order-1 xl:order-1">
						<h3 className="text-2xl uppercase text-custom-gray-head-text font-semibold tracking-[.15em]">welcome</h3>
						<p className="mt-2 text-custom-gray-text" style={{fontFamily: "Muli"}}>
						It will be a pleasure to welcome you to our academy! et's discover and explore your creative talent through visual art</p>
						<p className="text-custom-gray-text mb-4">courses:</p>
					</div>
					<div 
						className="relative flex justify-center 
							xl:block lg:block md::block sm::block 
							xl:justify-start lg:justify-start md:justify-start sm:justify-start
							order-1 md:order-2 sm:order-2 lg:order-2 xl:order-2">
						<div className="h-[350px] xl:h-[450px] lg:h-[350px] md:h-[350px] sm:h-[350px] bg-custom-gray-bg w-2/6 float-right"></div>
						<img src={welcome} alt="hello world" 
							className="absolute top-0 
							xl:-left-[18px] lg:left-2 md:left-10 sm:left-10
							mt-8 xl:mt-12 lg:mt-8 md:mt-8 sm:mt-[70px] 
							xl:w-fit lg:w-[400px] md:w-[400px] w-[400px] 
							xl:h-[350px] z-10"
						/>
					</div>
				</div>


				<div className="py-20" style={{backgroundImage:"url(https://solene.qodeinteractive.com/wp-content/uploads/2019/12/contact-2-bckgr-img.png)"}}>
					<div className="bg-white xl:mx-80 lg:mx-20 xl:px-[10%] lg:px-40 md:px-40 px-5 py-20">
						<div className="text-center uppercase mb-10">
							<h1 className="xl:text-[3em] lg:text-[2.5em] md:text-4xl text-3xl tracking-[.25em] text-custom-gray-head-text">our training form</h1>
							<h3 className="xl:text-2xl lg:text-xl text-md tracking-wide text-custom-gray-text mt-5">kindly fill in with correct information</h3>
						</div>

						<form onSubmit={academyForm}>
							<div className="grid grid-cols-2">
								<div className="mb-20">
									<input 
										className="
											border-solid 
											border-b 
											border-gray-500
											w-4/5" 
										type="text" 
										name="fullName"
										value={formData.fullName}
	        							onChange={handleInputChange}
										placeholder="FULL NAME" 
										style={{fontFamily: "Muli"}}
										required
									/>
								</div>
								<div>
									<input 
										className="
											border-solid 
											border-b 
											border-gray-500
											w-4/5" 
										type="text" 
										name="email"
										value={formData.email}
	        							onChange={handleInputChange}
										placeholder="E-MAIL" 
										style={{fontFamily: "Muli"}}
									/>
								</div>
								<div>
									<input className="
											border-solid 
											border-b 
											border-gray-500
											w-4/5"
										type="text" 
										name="phoneNumber"
										value={formData.phoneNumber}
	        							onChange={handleInputChange}
										placeholder="PHONE NUMBER" 
										style={{fontFamily: "Muli"}}
										required
									/>
								</div>
								<div>
									<input className="
											border-solid 
											border-b 
											border-gray-500
											w-4/5"
										type="text" 
										name="location"
										value={formData.location}
	        							onChange={handleInputChange}
										placeholder="WHERE ARE YOU LOCATED" 
										style={{fontFamily: "Muli"}}
									/>
								</div>

								<div className="mt-10">
									<label style={{fontFamily: "Muli"}}>Sex</label>
									<div className="flex">
										<label className="flex items-center">
									        <input
									          type="radio"
									          name="gender"
									          value="male"
	              							  checked={formData.gender === 'male'}
	        								  onChange={handleInputChange}
									          className="form-radio h-4 w-4 text-blue-500"
									          style={{fontFamily: "Muli"}}
									        />
									        <span className="ml-2" style={{fontFamily: "Muli"}}>Male</span>
									    </label>
									    <label className="flex items-center ml-4">
									        <input
									          type="radio"
									          name="gender"
											  value="female"
											  checked={formData.gender === 'female'}
	        								  onChange={handleInputChange}
									          className="form-radio h-4 w-4 text-blue-500"
									          style={{fontFamily: "Muli"}}
									        />
									        <span className="ml-2" style={{fontFamily: "Muli"}}>Female</span>
									    </label>
								    </div>
							    </div>

							    <div className="mt-10">
	                                <label style={{fontFamily: "Muli"}}>Any prior photography training?</label>
	                                <div className="flex">
		                                <span className="">
											<input 
												type="radio" 
												name="photoTraining" 
												value="Y" 
												checked={formData.photoTraining === 'Y'}
												onChange={handleInputChange}
												id="yes" 
												className="phototraining" />
											<label style={{fontFamily: "Muli"}}>Yes</label>
											<input 
												type="radio" 
												name="phototraining" 
												value="N" 
												checked={formData.photoTraining === 'N'}
												onChange={handleInputChange}
												id="no" 
												className="ml-4 mr-2" />
											<label style={{fontFamily: "Muli"}}>No</label>
										</span>
									</div>
								</div>

								<div className="mt-10">
									<label style={{fontFamily: "Muli"}}>What area of photography will you love to focus on?</label>
									<div className="">
										<label className="flex items-center">
									        <input
									          type="radio"
									          name="photographyFocus"
									          value="portrait"
									          checked={formData.photographyFocus === 'portrait'}
	        								  onChange={handleInputChange}
									          className="form-radio h-4 w-4 text-blue-500"
									        />
									        <span className="ml-2" style={{fontFamily: "Muli"}}>Portrait/Studio Photography</span>
									    </label>
									    <label className="flex items-center">
									        <input
									          type="radio"
									          name="photographyFocus"
									          value="wedding"
									          checked={formData.photographyFocus === 'wedding'}
	        								  onChange={handleInputChange}
									          className="form-radio h-4 w-4 text-blue-500"
									        />
									        <span className="ml-2" style={{fontFamily: "Muli"}}>Wedding Photography</span>
									    </label>
									    <label className="flex items-center">
									        <input
									          type="radio"
									          name="photographyFocus"
									          value="documentary"
									          checked={formData.photographyFocus === 'documentary'}
	        								  onChange={handleInputChange}
									          className="form-radio h-4 w-4 text-blue-500"
									        />
									        <span className="ml-2" style={{fontFamily: "Muli"}}>Documentary Photograp</span>
									    </label>
									    <label className="flex items-center">
									        <input
									          type="radio"
									          name="photographyFocus"
									          value="baby"
									          checked={formData.photographyFocus === 'baby'}
	        								  onChange={handleInputChange}
									          className="form-radio h-4 w-4 text-blue-500"
									        />
									        <span className="ml-2" style={{fontFamily: "Muli"}}>Baby Photography</span>
									    </label>
									    <label className="flex items-center">
									        <input
									          type="radio"
									          name="photographyFocus"
									          value="all"
									          checked={formData.photographyFocus === 'all'}
	        								  onChange={handleInputChange}
									          className="form-radio h-4 w-4 text-blue-500"
									        />
									        <span className="ml-2" style={{fontFamily: "Muli"}}>All of the above</span>
									    </label>
								    </div>
							    </div>

							    <div className="mt-10">
	                                <label style={{fontFamily: "Muli"}}>New Session is starting on October 4th, are you ready to make payment for the class?</label>
	                                <div className="flex">
		                                <span className="">
											<input 
												type="radio" 
												name="sessionPayment" 
												value="Y" 
												checked={formData.sessionPayment === 'Y'}
	        									onChange={handleInputChange}
												id="yes" 
												className="phototraining" 
											/>
											<label style={{fontFamily: "Muli"}}>Yes</label>
											<input 
												type="radio" 
												name="sessionPayment" 
												value="N" 
												checked={formData.sessionPayment === 'N'}
	        									onChange={handleInputChange}
												id="no" 
												className="ml-4 mr-2" 
											/>
											<label style={{fontFamily: "Muli"}}>No</label>
										</span>
									</div>
								</div>

								<div className="mt-10">
	                                <label style={{fontFamily: "Muli"}}>Do you have any health condition we need to know about?</label>
	                                <div className="flex">
		                                <span className="">
											<input 
												type="radio" 
												name="healthCondition" 
												value="Y" 
												checked={formData.healthCondition === 'Y'}
	        									onChange={handleInputChange}
												id="yes" 
												className="phototraining" 
											/>
											<label style={{fontFamily: "Muli"}}>Yes</label>
											<input 
												type="radio" 
												name="healthCondition" 
												value="N" 
												checked={formData.healthCondition === 'N'}
	        									onChange={handleInputChange}
												id="no" 
												className="ml-4 mr-2" 
											/>
											<label style={{fontFamily: "Muli"}}>No</label>
										</span>
									</div>
								</div>


							</div>

							<textarea
						      id="message"
						      name="message"
						      value={formData.message}
	        				  onChange={handleInputChange}
						      rows="4"
						      className="mt-20 focus:ring-blue-500 h-[200px] focus:border-blue-500 block w-full shadow-sm sm:text-sm border-b border-gray-500"
						      placeholder="DO YOU HAVE ANY QUESTION FOR US"
						      style={{fontFamily: "Muli"}}
						    ></textarea>
							<div className="mt-10 flex justify-center">
								<button className="bg-custom-dark-gray-bg px-20 py-3 tracking-[.25em] text-sm text-white uppercase" style={{fontFamily: "Muli"}}>send</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			*/}
			<Footer />
		</>
	)
}

export default AcademyMore