import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'

const Home = () => {
	const [username, setUsername] = useState(null)

	// const URL = import.meta.env.VITE_BASE_URL;

	useEffect(() => {
		fetch('https://glintz-serverss.vercel.app/profile', {
			credentials: 'include',
		}).then(response => {
			response.json().then(userInfo => {
				setUsername(userInfo.username)
			})
		})
	}, [])

	function logout() {
		fetch('https://glintz-serverss.vercel.app/logout', {
			credentials: 'include',
			methos: 'POST',
		})
		setUsername(null)
	}

	return (
		<>
			<header>
				<Link to="/">Admin</Link>
				<nav>
					{username && (
						<>
							<Link to="/create">Create new blog</Link>
							<a onClick={logout}>Logout</a>
						</>
					)}
					{!username && (
						<>
							<Link to="/admin/login">Login</Link>
							<Link to="/admin/register">Register</Link>
						</>
					)}
				</nav>
			</header>
		</>
	)
}

export default Home