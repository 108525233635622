import React, { useState, useEffect } from 'react';
import client1 from '../../assets/testimonial/client1.jpg'
import client2 from '../../assets/testimonial/client2.jpg'
import client3 from '../../assets/testimonial/client3.jpg'

const testimonials = [
  {
    id: 1,
    image: client1,
    name: '',
    text: 'I am glad I stumbled upon Glintz photography! He was instantly able to make me, my family at ease and capture the best moments. We love our wedding pictures and videos and would not hesitate to recommend Glintz to anyone who wants a truly professional experience',
  },
  {
    id: 2,
    image: client3,
    name: 'Mrs Bolade',
    text: "We absolutely love our frames and pictures. I can't count the number of times i looked at them."
  },
  {
    id: 3,
    image: client2,
    name: 'Mrs O',
    text: "Choosing Glintz Photography was one of the best decisions we made when choosing Vendors for our wedding. The service, temperament of the photographer and support, customer service and delivery were all to notch. The pictures and videos were beautifully captured. I must specifically commend the ability of Glintz to keep the bride and groom happy, calm and engaged. It is very clear, he is very pasionate about photography. I would recommend and use again!"
  },
  // Add more testimonials as needed
];

const TestimonialSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % testimonials.length);
  };

  useEffect(() => {
    const interval = setInterval(goToNextSlide, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const currentTestimonial = testimonials[currentSlide];

  // Split text into paragraphs
  // const paragraphs = currentTestimonial.text.split('. ').map((sentence, index) => (
  //   <p key={index} style={{ fontFamily: "Muli" }} className="text-gray-700 xl:mt-[30%] text-sm lg:mt-[15%] md:mt-[10%] transform transition-opacity duration-300">
  //     {sentence}.
  //   </p>
  // ));

  return (
    <div className="items-center justify-center h-[100%] bg-white xl:my-20 lg:my-20 md:my-10 my-4">
        <div className="xl:h-[500px] lg:h-[500px] xl:w-5/6 lg:w-5/6 md:w-5/6 px-6 xl:pb-20 lg:pb-20 mx-auto 
            grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1">
            <div className="z-10">
              <img
                src={currentTestimonial.image}
                alt={`Testimonial ${currentTestimonial.id}`}
                className="z-10 xl:w-full mb-4 lg:mb-0 lg:mr-4 transform transition-transform duration-300 hover:scale-105"
              />
            </div>
            <div className="flex flex-col items-center justify-center bg-gray-100 min-h-[150px] relative shadow-md xl:px-4 p-2 ">
            {/*xl:h-[100%] lg:h-[91%] md:h-[225px] sm:h-[208px] */}
                <div className="text-center">
                  <p className="xl:text-lg lg:text-text-lg md:text-base text-base" style={{fontFamily: "Muli"}}>{currentTestimonial.text}</p>
                </div>
                <br />
                <p style={{fontFamily: "Muli"}}>{currentTestimonial.name}</p>
            </div>
            <div className="mt-2">
              <div className="flex justify-end">
                <button
                  onClick={goToNextSlide}
                  className=" text-gray-700 text-lg px-4 py-2"
                >
                  Next
                </button>
              </div>
            </div>
        </div>
    </div>
  );
};

export default TestimonialSlider;
