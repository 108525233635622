import React from 'react'

const Footer = () => {
	return (
		<>
			<div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 xl:px-40 px-5 py-5 bg-custom-light-bg border-t border-solid border-custom-light-bg">
				<div>
					<p className="italic text-xl text-custom-gray-head-text xl:text-start text-center xl:mb-0 lg:mb-0 md:mb-0 mb-4">© 2021 Copyright Glintz Photography</p>
				</div>
				<div>
					<div className="flex uppercase justify-center items-center">
						<div className="px-2"><a href="https://web.facebook.com/glintzmedia?mibextid=ZbWKwL">fb</a> - </div>
						<div><a href="https://www.instagram.com/glintz_studios/">ig</a> -</div>
						<div><a href="https://www.instagram.com/glintzweddingphotography?igsh=MTJibTkyZzhjczljeg==">ig</a> -</div>
						<div className="px-2"><a href="https://x.com/glintzmedia?t=Lhsmk9m7viwv0muKKfviiQ&s=08">tw</a></div>
					</div>
				</div>
				{/*<div className="xl:flex justify-end ">
					<p className="text-custom-gray-head-text text-xl italic text-center 
						xl:mt-0 lg:mt-0 md:mt-0 mt-4">Developed By <a href="https://instagram.com/oprime.ng" className="text-custom-gray-text">Oprime Technologies</a></p>
				</div>*/}
			</div>
		</>
	)
}

export default Footer
