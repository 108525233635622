import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet";
import { useParams, Link } from 'react-router-dom'
import Modal from 'react-modal';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import photography from '../assets/about/photography.jpg'
import photo1 from '../assets/home/portraits2.jpg'
import photo2 from '../assets/home/portraits3.jpg'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import '../style.css'

const Portfolio = () => {

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);
	const [images, setImages] = useState([]);
	const [brandName, setBrandName] = useState('')
  const {brandName: urlBrandName} = useParams()

  // const URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    fetchImages();
    fetchBrand();
  }, []);
  
  const fetchImages = async () => {
    try {
      const response = await fetch(`https://glintz-serverss.vercel.app/upload/${urlBrandName}`);
      if (response.ok) {
        const data = await response.json();
        setImages(
          data.map((image) => ({
            src: image.image, // Use 'src' instead of 'original' for react-photo-gallery
            alt: image.alt,
          }))
        );
      } else {
        console.error('Error fetching images');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const fetchBrand = async () => {
    try {
      const response = await fetch('https://glintz-serverss.vercel.app/upload');
      if (response.ok) {
        const data = await response.json();

      	const matchingBrand = data.find(brand => brand.brandName === urlBrandName); // Assuming 'id' is the property to match
      	if (matchingBrand) {
          setBrandName(matchingBrand.brandName);
        }

      } else {
        console.error('Error fetching brands');
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };


	  const openModal = (index) => {
	    setSelectedImageIndex(index);
	    setModalIsOpen(true);
	  };

	  const closeModal = () => {
	    setModalIsOpen(false);
	  };

	return (
		<>
			<Helmet>
		    <title>Glintz Photography - Portfolio | Wedding, Portrait, Documentary, Events, Storytelling</title>
			  <meta name="description" content="Glintz Photography portfolio page" />
		  </Helmet>
			<Navbar />
			<div className="xl:pt-[50px] lg:pt-[50px] md:pt-[50px] sm:pt-[30px] pt-[10px]">
				<div className="grid grid-cols-2 h-28 xl:px-44 px-2 pt-10 " 
					style={{backgroundImage:"url(https://github.com/Imohh/glintz-route/blob/76337b00d6b03f87ba3ad6b52551b72c79caf830/src/assets/academy/academy-header.jpg)", 
							backgroundSize: "100%",
						    backgroundPosition: "center center"}}>
					<div>
						<p className="text-black xl:text-2xl lg:text-2xl md:text-2xl text-xl uppercase tracking-[.15em]">{brandName}</p>
					</div>
					<div>
						<p className="text-black float-right italic text-lg"><Link to="/">Home</Link> / <span className="capitalize">{brandName}</span></p>
					</div>
				</div>

				<div className="mt-10">

				</div>

				<div className="mt-20">
					{/*<Gallery
				    items={images}
				    onClickThumbnail={(index) => openModal(index)}
			    />

				    <Modal
				      isOpen={modalIsOpen}
				      onRequestClose={closeModal}
				      contentLabel="Image Lightbox"
				      className="modal"
			        overlayClassName="overlay"
				    >
				      <button className="modal-close" onClick={closeModal}>
				        Close
				      </button>
				      <Gallery
			          items={images}
			          startIndex={selectedImageIndex}
			        />
				    </Modal>*/}
					<div className="xl:px-20 lg:px-20 md:px-20 px-10">
						<div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4">
							{images.map((image, index) => (
								<div>
				          <img
				            key={index}
				            src={image.src}
				            alt={image.alt}
				            className="w-full h-auto cursor-pointer"
				          />
								</div>
				      ))}
						</div>
					</div>

				</div>

				<div className="grid grid-cols-1 text-center py-20 hover:bg-custom-dark-gray-bg transition">
					<h1 
						className="
							xl:text-4xl lg:text-4xl md:text-3xl text-2xl tracking-[.25em] uppercase mb-5 
							text-white">
							follow us on instagram
					</h1>
					<p className="mb-2 text-white italic text-xl">
						<a href="https://www.instagram.com/glintzweddingphotography?igsh=MTJibTkyZzhjczljeg==">@glintzweddingphotography</a>
					</p>
					<p className="text-white italic text-xl">
						<a href="https://instagram.com/glintz_studios">@glintz_studios</a>
					</p>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default Portfolio